import React, { useCallback, useMemo, useRef, useState } from 'react';
import Scheduler, { View } from 'devextreme-react/scheduler';
import './schedulerComponent.scss';
import { LoadPanel } from 'devextreme-react';
import Utils from './utils';
import { AppointmentComponent } from './SchedulerRenderers';
import { useTranslation } from 'react-i18next';
import { WorkingHoursDto, useGetCalendarForUserByPeriodQuery } from '../../api/medapp/calendar.generated';
import { AppointmentDTO } from '../../api/medapp/generated';
import {
  useDeleteAppointmentMutation,
  useCreateAppointmentMutation,
  useEditAppointmentMutation,
  useUpdateAppointmentStatusMutation,
} from '../../api/medapp/appointment.generated';
import { cloneDeep, flattenDeep, last } from 'lodash';
import { Appointment, AppointmentRenderedEvent } from 'devextreme/ui/scheduler';
import dxForm from 'devextreme/ui/form';
import dxSelectBox from 'devextreme/ui/select_box';
import Tooltip from './Tooltip';
import { calculateAppointmentColor, generatePhotoBySize } from '../helpers';
import { DateTime, Interval } from 'luxon';
import notify from 'devextreme/ui/notify';
import { useAppSelector } from '../../redux/store';
import { isManagerSelector, languageSelector } from '../../redux/modules/user';
import { confirm } from 'devextreme/ui/dialog';
import Toolbar from 'devextreme/ui/toolbar';
import Holidays from 'date-holidays';
import { useGetAllClinicsMultilangQuery } from '../../api/medapp/clinic.generated';
import { ClinicMultiLang } from '../../api';
import { AlgoliaItem } from '../Algolia/autocomplete';

type CreateAppointmentDTO = Pick<
  AppointmentDTO,
  'appointmentType' | 'status' | 'userId' | 'patientName' | 'phoneNumber' | 'startAt' | 'endAt'
>;

export interface SchedulerComponentProps {
  user: AlgoliaItem | null;
}

function extractNumber(value: string): number | null {
  const match = value.match(/\d+/);
  return match ? parseInt(match[0]) : null;
}
const hd = new Holidays();

export const SchedulerComponent = (props: SchedulerComponentProps): JSX.Element => {
  const { user } = props;

  const isManager = useAppSelector(isManagerSelector);
  const languageId = useAppSelector(languageSelector);

  const { t } = useTranslation();

  const appointmentTypesTranslations = t('appointmentTypes', { returnObjects: true });
  const appointmentStatusesTranslations = t('appointmentStatuses', { returnObjects: true });

  const schedulerRef = useRef<Scheduler>(null);

  const onAppointmentRender = useCallback((e: AppointmentRenderedEvent) => {
    const { appointmentData } = e;
    const width = e.element.querySelector('.dx-scheduler-date-table-cell')?.clientWidth; // get a cell's width
    if (width) {
      e.appointmentElement.style.width = `${width - 1}px`;
    }
    const height = extractNumber(e.appointmentElement.style.height);
    if (height) {
      e.appointmentElement.style.height = `${height - 1}px`;
    }
    e.appointmentElement.style.backgroundColor = calculateAppointmentColor(appointmentData) ?? '';
  }, []);

  const appointmentRenderer = useCallback((e: { targetedAppointmentData: AppointmentDTO }) => {
    const { targetedAppointmentData } = e;
    return AppointmentComponent(targetedAppointmentData);
  }, []);

  const [deleteAppointment] = useDeleteAppointmentMutation();
  const [createAppointment] = useCreateAppointmentMutation();
  const [updateAppointment] = useEditAppointmentMutation();
  const [updateStatus] = useUpdateAppointmentStatusMutation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentViewStartDate, setCurrentViewStartDate] = useState('');
  const [currentViewEndDate, setCurrentViewEndDate] = useState('');

  const setStartDate = useCallback(() => {
    const startDate = schedulerRef.current?.instance.getStartViewDate().toISOString().split('T')[0];
    if (startDate && startDate !== currentViewStartDate) {
      setCurrentViewStartDate(startDate);
    }
  }, [currentViewStartDate]);

  const setEndDate = useCallback(() => {
    const endDate = schedulerRef.current?.instance.getEndViewDate().toISOString().split('T')[0];
    if (endDate && endDate !== currentViewEndDate) {
      setCurrentViewEndDate(endDate);
    }
  }, [currentViewEndDate]);

  const { currentData: calendarData, isFetching: isFetchingCalendar } = useGetCalendarForUserByPeriodQuery(
    {
      userId: Number(user?.objectID),
      startDate: currentViewStartDate,
      endDate: currentViewEndDate,
    },
    { skip: user === null, refetchOnMountOrArgChange: true },
  );

  const { currentData: allClinics, isFetching: isFetchingClinics } = useGetAllClinicsMultilangQuery();

  const scheduleClinicCode = useMemo(() => {
    if (calendarData?.schedules?.[0]) {
      const clinicId = calendarData.schedules[0]?.clinicId;
      if (clinicId) {
        const clinicCountryCode = allClinics?.find((clinic: ClinicMultiLang) => clinic.id === clinicId)?.countryCode;
        return clinicCountryCode?.toUpperCase() || '';
      }
    }
    return '';
  }, [allClinics, calendarData]);

  hd.init(scheduleClinicCode, { types: ['public'] });

  const scheduleIntervals: Interval[] = useMemo(() => {
    const intervals: Interval[] = [];
    calendarData?.schedules?.forEach((schedule: any) =>
      intervals.push(Interval.fromDateTimes(new Date(schedule.startTime), new Date(schedule.endTime))),
    );
    return intervals;
  }, [calendarData?.schedules]);

  const workingHoursForCurrentView = useMemo(() => {
    const workingHours: WorkingHoursDto[][] = [];
    const viewStartDate = schedulerRef.current?.instance.getStartViewDate();
    const viewEndDate = schedulerRef.current?.instance.getEndViewDate();

    calendarData?.schedules?.forEach((schedule) => {
      if (viewStartDate && viewEndDate && schedule.workingHours) {
        workingHours.push(schedule.workingHours);
      }
    });
    return flattenDeep(workingHours);
  }, [calendarData?.schedules]);

  const daysOff = hd.getHolidays(new Date(currentViewStartDate).getFullYear());

  const calculateDaysOffForCountry = useMemo(() => {
    if (scheduleClinicCode === 'BG') {
      const modifiedDaysOffForBg = daysOff
        .map((day) => {
          const dayDate = new Date(day.date);
          if (dayDate.getDay() === 0) {
            // 0 is sunday => if day is sunday -> make monday off
            const nextWorkingDay = new Date(dayDate);
            nextWorkingDay.setDate(nextWorkingDay.getDate() + 1);
            const dateResult = DateTime.fromJSDate(nextWorkingDay);
            return [{ date: dateResult.toFormat('yyyy-MM-dd HH:mm:ss'), name: day.name }];
          }

          if (dayDate.getDay() === 6) {
            // 6 is saturday => if day is saturday -> make monday off
            const nextWorkingDay = new Date(dayDate);
            nextWorkingDay.setDate(nextWorkingDay.getDate() + 2);
            const dateResult = DateTime.fromJSDate(nextWorkingDay);
            return [{ date: dateResult.toFormat('yyyy-MM-dd HH:mm:ss'), name: day.name }];
          }

          return [day];
        })
        .flat();
      return modifiedDaysOffForBg;
    } else return daysOff;
  }, [daysOff, scheduleClinicCode]);

  const onDelete = useCallback(
    (e: any) => {
      deleteAppointment({ appointmentId: e.appointmentData.id });
    },
    [deleteAppointment],
  );

  const onEdit = useCallback(
    (e: { newData: Appointment }) => {
      const { newData } = e;
      updateAppointment({ appointmentId: newData.id, appointmentDto: newData as AppointmentDTO });
    },
    [updateAppointment],
  );

  const onAttend = useCallback(
    (id: number, status: AppointmentDTO.status) => {
      updateStatus({ appointmentId: id, status: status });
    },
    [updateStatus],
  );

  const onCreate = useCallback(
    (e: { appointmentData: Appointment }) => {
      const { appointmentData } = e;

      if (user?.objectID === null) {
        notify('Cannot create appointment without userId');
        return;
      }

      let newAppointment: CreateAppointmentDTO = {
        userId: Number(user?.objectID),
        patientName: appointmentData.patientName,
        phoneNumber: appointmentData.phoneNumber,
        status: appointmentData.status,
        appointmentType: appointmentData.appointmentType,
        startAt: appointmentData.startAt,
      };
      if (appointmentData.status === 'OTHER' || 'ABSENCE') {
        newAppointment.endAt = appointmentData.endAt;
      }

      createAppointment({
        appointmentDto: newAppointment,
      });
    },
    [user, createAppointment],
  );

  const generateItems = useCallback((data: any) => {
    const items = Object.keys(data).map((key) => ({
      value: key,
      displayValue: data[key],
    }));
    return items;
  }, []);

  const onAppointmentFormOpening = useCallback(
    (e: { form: dxForm; appointmentData?: Appointment; cancel?: boolean }) => {
      const { form } = e;
      if (e.appointmentData?.startAt) {
        const startDate = new Date(e.appointmentData.startAt);
        //const isValid = Utils.isAllowedTime(startDate, endDate, freeTimeSlots);
        if (workingHoursForCurrentView && workingHoursForCurrentView.length > 0) {
          const isValid = Utils.isEnabledTime(new Date(startDate), workingHoursForCurrentView, scheduleIntervals);
          if (!isValid) {
            e.cancel = true;
          }
        }
      }
      const appointmentStatuses = generateItems(appointmentStatusesTranslations).filter(
        (status) => status.value === 'SCHEDULED' || status.value === 'COMPLETED' || status.value === 'CANCELLED',
      );
      const appointmentTypes = generateItems(appointmentTypesTranslations).filter(
        (type) =>
          type.value === AppointmentDTO.appointmentType.INITIAL ||
          type.value === AppointmentDTO.appointmentType.SECONDARY ||
          (isManager && type.value === AppointmentDTO.appointmentType.ABSENCE) ||
          type.value === AppointmentDTO.appointmentType.OTHER,
      );

      const customItems = [
        {
          dataField: 'patientName',
          colSpan: 2,
          editorType: 'dxTextBox',
          label: { text: `${t('appointmentForm.patientName')}` },
          isRequired: true,
          visible: e.appointmentData?.appointmentType !== AppointmentDTO.appointmentType.ABSENCE,
        },
        {
          dataField: 'phoneNumber',
          colSpan: 2,
          editorType: 'dxTextBox',
          label: { text: `${t('appointmentForm.phone')}` },
          isRequired: true,
          visible: e.appointmentData?.appointmentType !== AppointmentDTO.appointmentType.ABSENCE,
        },
        {
          dataField: 'appointmentType',
          editorType: 'dxSelectBox',
          colSpan: 2,
          label: { text: `${t('appointmentType')}` },
          editorOptions: {
            items: appointmentTypes,
            valueExpr: 'value',
            displayExpr: 'displayValue',
            onInitialized: (e: { component: dxSelectBox }) => {
              const { component } = e;
              const value = component.option('value');
              if (value === null) {
                e.component.option('value', appointmentTypes[0].value);
                form.updateData({ appointmentType: appointmentTypes[0].value });
              }
            },
            onValueChanged: (e: { value: any }) => {
              const { value } = e;
              const isVisibleEndAt =
                value === AppointmentDTO.appointmentType.OTHER || value === AppointmentDTO.appointmentType.ABSENCE;
              const isVisiblePatientName = value === AppointmentDTO.appointmentType.ABSENCE ? false : true;
              const isVisiblePhone = value === AppointmentDTO.appointmentType.ABSENCE ? false : true;
              const isVisibleStatus = value === AppointmentDTO.appointmentType.ABSENCE ? false : true;

              const patientNameItemIndex = customItems.findIndex((item) => item.dataField === 'patientName');
              const endAtItemIndex = customItems.findIndex((item) => item.dataField === 'endAt');
              const phoneItemIndex = customItems.findIndex((item) => item.dataField === 'phoneNumber');
              const statusItemIndex = customItems.findIndex((item) => item.dataField === 'status');

              if (endAtItemIndex !== -1) {
                customItems[endAtItemIndex].visible = isVisibleEndAt;
              }
              if (patientNameItemIndex !== -1) {
                customItems[patientNameItemIndex].visible = isVisiblePatientName;
              }
              if (phoneItemIndex !== -1) {
                customItems[phoneItemIndex].visible = isVisiblePhone;
              }
              if (statusItemIndex !== -1) {
                customItems[statusItemIndex].visible = isVisibleStatus;
              }
              form.option('items', customItems);
            },
          },
        },
        {
          dataField: 'status',
          editorType: 'dxSelectBox',
          label: { text: `${t('appointmentStatus')}` },
          colSpan: 2,
          visible: e.appointmentData?.appointmentType !== AppointmentDTO.appointmentType.ABSENCE,
          editorOptions: {
            items: appointmentStatuses,
            valueExpr: 'value',
            displayExpr: 'displayValue',
            onInitialized: (e: { component: dxSelectBox }) => {
              const { component } = e;
              const value = component.option('value');
              if (value === null) {
                e.component.option('value', appointmentStatuses[0].value);
                form.updateData({ status: appointmentStatuses[0].value });
              }
            },
          },
        },
        {
          dataField: 'startAt',
          colSpan: 2,
          editorType: 'dxDateBox',
          editorOptions: { type: 'datetime' },
          label: { text: `${t('appointmentForm.startAt')}` },
        },
        {
          dataField: 'endAt',
          colSpan: 2,
          editorType: 'dxDateBox',
          editorOptions: { type: 'datetime' },
          label: { text: `${t('appointmentForm.endAt')}` },
          visible:
            e.appointmentData?.appointmentType === AppointmentDTO.appointmentType.ABSENCE ||
            e.appointmentData?.appointmentType === AppointmentDTO.appointmentType.OTHER,
        },
      ];

      form.option('items', customItems);
    },
    [
      appointmentStatusesTranslations,
      appointmentTypesTranslations,
      generateItems,
      isManager,
      scheduleIntervals,
      t,
      workingHoursForCurrentView,
    ],
  );

  const appointmentTooltip = useCallback(
    (props: any) => {
      // NOTE: You can use props.appointmentData.resouceId to obtain resource color
      let color;

      const onDeleteButtonClick = async (e: any) => {
        e.event.stopPropagation();

        const result = await confirm(t('toolTip.deleteAppointmentConfirmMsg'), t('toolTip.deleteAppointmentTitle'));

        if (result) {
          schedulerRef.current?.instance.deleteAppointment(props.appointmentData);
          schedulerRef.current?.instance.hideAppointmentTooltip();
        }
      };

      const onUserAttended = (e: any, status: AppointmentDTO.status) => {
        onAttend(props.appointmentData.id, status);
        e.event.stopPropagation();
        schedulerRef.current?.instance.hideAppointmentTooltip();
      };

      return (
        <Tooltip
          {...props}
          isDeleteButtonExist={true}
          onDeleteButtonClick={onDeleteButtonClick}
          color={color}
          onUserAttended={onUserAttended}
        />
      );
    },
    [onAttend, t],
  );

  //https://supportcenter.devexpress.com/ticket/details/t1148405/scheduler-create-custom-buttons-in-the-toolbar
  //https://supportcenter.devexpress.com/ticket/details/t1086863/dynamic-filtering-on-a-scheduler-calendar
  const onContentReady = useCallback(
    (e: any) => {
      setStartDate();
      setEndDate();

      const toolbarInstance = Toolbar.getInstance(e.element.querySelector('.dx-toolbar'));
      const items = toolbarInstance.option('items') as unknown[];
      if (items.length === 2) {
        toolbarInstance.option('items', [
          items[0],
          {
            widget: 'dxButton',
            location: 'before',
            options: {
              text: t('buttons.todayButton') || '',
              onClick: () => {
                const currentDate = new Date();
                schedulerRef.current?.instance.option('currentDate', currentDate);
              },
            },
          },
          items[1],
        ]);
      }

      if (user?.profilePicture !== undefined && user?.profilePicture !== null) {
        const headerCell = e.element.querySelector('.dx-scheduler-header-panel-empty-cell');
        const cellWidth = headerCell.offsetWidth;
        const cellHeight = headerCell.offsetHeight;

        headerCell.style.height = `${cellHeight}px`;
        headerCell.style.width = `${cellWidth}px`;

        headerCell.innerHTML = '';

        const img = document.createElement('img');
        img.src = generatePhotoBySize('small', user.profilePicture);

        headerCell.appendChild(img);
      }
    },
    [setEndDate, setStartDate, t, user?.profilePicture],
  );

  const isNationalHoliday = useCallback(
    (date: Date) => {
      for (let i = 0; i < calculateDaysOffForCountry.length; i++) {
        const datee = new Date(calculateDaysOffForCountry[i].date);
        if (datee.toDateString() === date.toDateString()) {
          return true;
        }
      }

      return false;
    },
    [calculateDaysOffForCountry],
  );

  const dataCellTemplate = useCallback(
    (itemData: { startDate: string | number | Date }, itemIndex: number, itemElement: HTMLElement) => {
      let isTimeSlotEnabled = false;
      let isHoliday = false;

      if (workingHoursForCurrentView && workingHoursForCurrentView.length > 0) {
        const startDate = new Date(itemData.startDate);
        const isEnabledTime = Utils.isEnabledTime(startDate, workingHoursForCurrentView, scheduleIntervals);

        isHoliday = isNationalHoliday(startDate);
        isTimeSlotEnabled = isEnabledTime;
      }
      if (!isTimeSlotEnabled) {
        itemElement.classList.add('disable-date');
      }
      if (isHoliday) {
        itemElement.classList.add('date-is-national-holiday');
      }
    },
    [isNationalHoliday, scheduleIntervals, workingHoursForCurrentView],
  );

  const calculateCellDuration = useMemo(() => {
    let cellDuration = 5;
    const schedules = last(calendarData?.schedules);
    const workingHours = schedules?.workingHours;
    const initialAppointment = schedules?.usualInitialAppointmentDuration;
    const secondaryAppointment = schedules?.usualSecondaryAppointmentDuration;
    const interval = schedules?.intervalBetweenAppointments;

    if (workingHours) {
      const allMinutes: number[] = [];

      for (const day of workingHours) {
        if (day.startTime && day.endTime) {
          const startMinutes = new Date(day.startTime).getMinutes();
          const endMinutes = new Date(day.endTime).getMinutes();

          if (startMinutes !== 0) {
            allMinutes.push(startMinutes);
          }
          if (endMinutes !== 0) {
            allMinutes.push(endMinutes);
          }
        }
      }

      if (initialAppointment) {
        allMinutes.push(initialAppointment as number);
      }

      if (secondaryAppointment) {
        allMinutes.push(secondaryAppointment as number);
      }

      if (interval) {
        allMinutes.push(interval as number);
      }

      const maxNum = Math.max(...allMinutes);

      for (let i = maxNum; i > 0; i--) {
        let isCommonMultiple = true;

        for (let mins of allMinutes) {
          if (mins % i !== 0) {
            isCommonMultiple = false;
            break;
          }
        }

        if (isCommonMultiple) {
          cellDuration = i;
          break;
        }
      }
    }

    return cellDuration;
  }, [calendarData?.schedules]);

  const calculateStartHour = useCallback(() => {
    const startHours = workingHoursForCurrentView?.map((wh) => wh.startTime);
    if (startHours && startHours.length > 0) {
      const earliestHour = Math.min(...startHours.map((time: any) => new Date(time).getHours()));
      return earliestHour;
    }
    return 6;
  }, [workingHoursForCurrentView]);

  const calculateEndHour = useCallback(() => {
    const endHours = workingHoursForCurrentView?.map((wh) => wh.endTime);
    if (endHours && endHours.length > 0) {
      const latestHour = Math.max(...endHours.map((time: any) => new Date(time).getHours()));
      //add +1 hour in order not to cut last appointments
      return latestHour + 1;
    }
    return 20;
  }, [workingHoursForCurrentView]);

  const timeCellTemplate = useCallback(
    (
      itemData: { date: Date },
      itemIndex: number,
      itemElement: {
        appendChild: (arg0: HTMLDivElement) => void;
        classList: { add: (arg0: string, arg1: string) => void };
      },
    ) => {
      let formattedTime = DateTime.fromJSDate(itemData.date).setLocale(languageId).toLocaleString(DateTime.TIME_SIMPLE);
      const timeDiv = document.createElement('div');
      timeDiv.innerText = formattedTime;
      itemElement.appendChild(timeDiv);
      itemElement.classList.add('dx-scheduler-time-panel-cell', 'dx-scheduler-cell-sizes-vertical');
    },
    [languageId],
  );

  return (
    <>
      <LoadPanel
        visible={isFetchingCalendar || isFetchingClinics}
        position={{ my: 'center', at: 'center', of: `#scheduler` }}
        shading={true}
        shadingColor={'rgba(0,0,0,.32)'}
        height="100%"
      />
      <Scheduler
        ref={schedulerRef}
        id="scheduler"
        height={'90%'}
        cellDuration={calculateCellDuration}
        dataSource={cloneDeep(calendarData?.appointments ?? [])}
        defaultCurrentView="week"
        textExpr="patientName"
        descriptionExpr="phoneNumber"
        allDayPanelMode="hidden"
        recurrenceRuleExpr="recurrence"
        firstDayOfWeek={1}
        startDayHour={calculateStartHour()}
        endDayHour={calculateEndHour()}
        adaptivityEnabled={true}
        startDateExpr="startAt"
        endDateExpr="endAt"
        //https://supportcenter.devexpress.com/ticket/details/t933359/scheduler-rendering-is-slow-on-switching-views-when-datacellcomponent-is-specified
        dataCellTemplate={dataCellTemplate}
        appointmentRender={appointmentRenderer}
        onAppointmentRendered={onAppointmentRender}
        onAppointmentDeleting={onDelete}
        onAppointmentAdding={onCreate}
        onAppointmentFormOpening={onAppointmentFormOpening}
        onAppointmentUpdating={onEdit}
        appointmentTooltipRender={appointmentTooltip}
        onContentReady={onContentReady}
        timeCellTemplate={timeCellTemplate}
        /*
        onAppointmentFormOpening={(e) => onAppointmentFormOpening(e, freeTimeSlots)}
        onAppointmentAdding={(e) => onAppointmentAdding(e, freeTimeSlots)}
        onAppointmentUpdating={(e) => onAppointmentUpdating(e, freeTimeSlots)}
        appointmentRender={({ targetedAppointmentData }) => appointmentRenderer(targetedAppointmentData)}
        onContentReady={onContentReady}
 */
      >
        <View type="day" />
        <View type="week" />
        <View type="month" />
      </Scheduler>
    </>
  );
};
