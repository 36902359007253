import { medAppApi as api } from '../medappApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    editWorkingHours: build.mutation<EditWorkingHoursApiResponse, EditWorkingHoursApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/workinghours/edit/${queryArg.workingHoursId}`,
        method: 'PUT',
        body: queryArg.workingHoursDto,
        headers: { timeZone: queryArg.timeZone },
      }),
    }),
    createWorkingHours: build.mutation<CreateWorkingHoursApiResponse, CreateWorkingHoursApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/workinghours/create/${queryArg.scheduleId}`,
        method: 'POST',
        body: queryArg.workingHoursDto,
        headers: { timeZone: queryArg.timeZone },
      }),
    }),
    getWorkingHoursBySchedule: build.query<GetWorkingHoursByScheduleApiResponse, GetWorkingHoursByScheduleApiArg>({
      query: (queryArg) => ({ url: `/api/v1/workinghours/${queryArg.scheduleId}` }),
    }),
    getWorkingHoursDetails: build.query<GetWorkingHoursDetailsApiResponse, GetWorkingHoursDetailsApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/workinghours/details/${queryArg.workingHoursId}`,
        params: { language: queryArg.language },
      }),
    }),
    getAllWorkingHours: build.query<GetAllWorkingHoursApiResponse, GetAllWorkingHoursApiArg>({
      query: () => ({ url: `/api/v1/workinghours/all` }),
    }),
    deleteWorkingHours: build.mutation<DeleteWorkingHoursApiResponse, DeleteWorkingHoursApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/workinghours/delete/${queryArg.workingHoursId}`,
        method: 'DELETE',
        headers: { timeZone: queryArg.timeZone },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type EditWorkingHoursApiResponse = /** status 200 OK */ WorkingHoursDto;
export type EditWorkingHoursApiArg = {
  workingHoursId: number;
  timeZone?: string;
  workingHoursDto: WorkingHoursDto;
};
export type CreateWorkingHoursApiResponse = /** status 200 OK */ WorkingHoursDto;
export type CreateWorkingHoursApiArg = {
  scheduleId: number;
  timeZone?: string;
  workingHoursDto: WorkingHoursDto;
};
export type GetWorkingHoursByScheduleApiResponse = /** status 200 OK */ WorkingHoursDto[];
export type GetWorkingHoursByScheduleApiArg = {
  scheduleId: number;
};
export type GetWorkingHoursDetailsApiResponse = /** status 200 OK */ ObjectRevisionDto[];
export type GetWorkingHoursDetailsApiArg = {
  workingHoursId: number;
  language?: string;
};
export type GetAllWorkingHoursApiResponse = /** status 200 OK */ WorkingHoursDto[];
export type GetAllWorkingHoursApiArg = void;
export type DeleteWorkingHoursApiResponse = /** status 200 OK */ object;
export type DeleteWorkingHoursApiArg = {
  workingHoursId: number;
  timeZone?: string;
};
export type WorkingHoursDto = {
  id?: number;
  clinicId?: number;
  scheduleId?: number;
  dayOfWeek?: 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';
  startTime?: string;
  endTime?: string;
  breakStartTime?: string;
  breakEndTime?: string;
};
export type UserDto = {
  id?: number;
  firstName?: string;
  lastName?: string;
  username?: string;
  clinicIds?: number[];
  specialityIds?: number[];
  phoneNumber?: string;
  emailAddress?: string;
  address?: string;
  profilePicture?: string;
  type?: 'ADMIN' | 'MANAGER' | 'REGISTRATOR' | 'SPECIALIST' | 'ASSISTANT';
};
export type ObjectRevisionDto = {
  object?: object;
  revisionType?: string;
  revisionDate?: number;
  revisionId?: number;
  user?: UserDto;
};
export const {
  useEditWorkingHoursMutation,
  useCreateWorkingHoursMutation,
  useGetWorkingHoursByScheduleQuery,
  useGetWorkingHoursDetailsQuery,
  useGetAllWorkingHoursQuery,
  useDeleteWorkingHoursMutation,
} = injectedRtkApi;
