import { medAppApi as api } from '../medappApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateClinic: build.mutation<UpdateClinicApiResponse, UpdateClinicApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/clinic/edit/${queryArg.clinicId}`,
        method: 'PUT',
        body: queryArg.clinicMultiLangDto,
      }),
    }),
    createClinic: build.mutation<CreateClinicApiResponse, CreateClinicApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/clinic/create`,
        method: 'POST',
        body: queryArg.clinicMultiLangDto,
        params: { language: queryArg.language },
      }),
    }),
    getClinicDetails: build.query<GetClinicDetailsApiResponse, GetClinicDetailsApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/clinic/details/${queryArg.clinicId}`,
        params: { language: queryArg.language },
      }),
    }),
    getClinicsForCurrentUser: build.query<GetClinicsForCurrentUserApiResponse, GetClinicsForCurrentUserApiArg>({
      query: (queryArg) => ({ url: `/api/v1/clinic/currentUser`, params: { lang: queryArg.lang } }),
    }),
    getAllClinics: build.query<GetAllClinicsApiResponse, GetAllClinicsApiArg>({
      query: (queryArg) => ({ url: `/api/v1/clinic/all`, params: { language: queryArg.language } }),
    }),
    getAllClinicsMultilang: build.query<GetAllClinicsMultilangApiResponse, GetAllClinicsMultilangApiArg>({
      query: () => ({ url: `/api/v1/clinic/all-multilingual` }),
    }),
    getHangingAppointmentsForClinic: build.query<
      GetHangingAppointmentsForClinicApiResponse,
      GetHangingAppointmentsForClinicApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/appointment/hanging/clinic/${queryArg.clinicId}` }),
    }),
    deleteClinic: build.mutation<DeleteClinicApiResponse, DeleteClinicApiArg>({
      query: (queryArg) => ({ url: `/api/v1/clinic/delete/${queryArg.clinicId}`, method: 'DELETE' }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type UpdateClinicApiResponse = /** status 200 OK */ ClinicMultiLangDto;
export type UpdateClinicApiArg = {
  clinicId: number;
  clinicMultiLangDto: ClinicMultiLangDto;
};
export type CreateClinicApiResponse = /** status 200 OK */ ClinicDto;
export type CreateClinicApiArg = {
  language: string;
  clinicMultiLangDto: ClinicMultiLangDto;
};
export type GetClinicDetailsApiResponse = /** status 200 OK */ ObjectRevisionDto[];
export type GetClinicDetailsApiArg = {
  clinicId: number;
  language?: string;
};
export type GetClinicsForCurrentUserApiResponse = /** status 200 OK */ ClinicDto[];
export type GetClinicsForCurrentUserApiArg = {
  lang: string;
};
export type GetAllClinicsApiResponse = /** status 200 OK */ ClinicDto[];
export type GetAllClinicsApiArg = {
  language: string;
};
export type GetAllClinicsMultilangApiResponse = /** status 200 OK */ ClinicMultiLangDto[];
export type GetAllClinicsMultilangApiArg = void;
export type GetHangingAppointmentsForClinicApiResponse = /** status 200 OK */ AppointmentDto[];
export type GetHangingAppointmentsForClinicApiArg = {
  clinicId: number;
};
export type DeleteClinicApiResponse = /** status 200 OK */ object;
export type DeleteClinicApiArg = {
  clinicId: number;
};
export type LocalizedStringDto = {
  language: string;
  value: string;
};
export type ClinicMultiLangDto = {
  id?: number;
  name: LocalizedStringDto[];
  phoneNumber?: string;
  organizationId: number;
  addressId: number;
  countryCode?: string;
  logoFileName?: string;
  coverPhotoFileName?: string;
};
export type ClinicDto = {
  id: number;
  userIds?: number[];
  name: string;
  address?: string;
  countryCode?: string;
  phoneNumber?: string;
  emailAddress?: string;
  logoFileName?: string;
  coverPhotoFileName?: string;
};
export type UserDto = {
  id?: number;
  firstName?: string;
  lastName?: string;
  username?: string;
  clinicIds?: number[];
  specialityIds?: number[];
  phoneNumber?: string;
  emailAddress?: string;
  address?: string;
  profilePicture?: string;
  type?: 'ADMIN' | 'MANAGER' | 'REGISTRATOR' | 'SPECIALIST' | 'ASSISTANT';
};
export type ObjectRevisionDto = {
  object?: object;
  revisionType?: string;
  revisionDate?: number;
  revisionId?: number;
  user?: UserDto;
};
export type AppointmentDto = {
  id?: number;
  userId: number;
  patientId?: number;
  patientName?: string;
  phoneNumber?: string;
  status: 'SCHEDULED' | 'PENDING' | 'COMPLETED' | 'CANCELLED';
  appointmentType: 'INITIAL' | 'SECONDARY' | 'OPEN' | 'INSURANCE' | 'OTHER' | 'ABSENCE';
  startAt: string;
  endAt?: string;
};
export const {
  useUpdateClinicMutation,
  useCreateClinicMutation,
  useGetClinicDetailsQuery,
  useGetClinicsForCurrentUserQuery,
  useGetAllClinicsQuery,
  useGetAllClinicsMultilangQuery,
  useGetHangingAppointmentsForClinicQuery,
  useDeleteClinicMutation,
} = injectedRtkApi;
