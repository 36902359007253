/**
 * @file This file creates the redux store with all desired configurations.
 */

import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import thunk, { ThunkAction } from 'redux-thunk';
import rootReducer, { RootState } from './modules';
import { medAppApi } from '../api/medappApi';
import { rtkQueryErrorLogger } from './helpers/errorHandlingMiddleware';
import { registerEnhancedScheduleEndpoints } from '../api/medapp/schedule.enhanced';
import { registerEnhancedAppointmentEndpoints } from '../api/medapp/appointment.enhanced';
import { registerEnhancedLanguageEndpoints } from '../api/medapp/language.enhanced';
import { registerEnhancedCountryEndpoints } from '../api/medapp/country.enhanced';
import { registerEnhancedRegionEndpoints } from '../api/medapp/region.enhanced';
import { registerEnhancedCityEndpoints } from '../api/medapp/city.enhanced';
import { registerEnhancedOrganizationEndpoints } from '../api/medapp/organization.enhanced';
import { registerEnhancedAddressEndpoints } from '../api/medapp/address.enhanced';
import { registerEnhancedClinicEndpoints } from '../api/medapp/clinic.enhanced';
import { registerEnhancedUserEndpoints } from '../api/medapp/user.enhanced';
import { registerEnhancedWorkingHoursEndpoints } from '../api/medapp/workingHours.enhanced';
import { registerEnhancedCalendarEndpoints } from '../api/medapp/calendar.enhanced';

declare module 'redux' {
  interface Dispatch<A extends Action = AnyAction> {
    <S, E, R>(asyncAction: ThunkAction<R, S, E, A>): R;
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createStoreWithMiddleware(preloadedState?: RootState) {
  // Thunks are action creators where sideeffects are allowed (this allows to use the state and dispatch actions inside action creator)
  const middleware = [thunk, medAppApi.middleware, rtkQueryErrorLogger];

  // Create the redux store
  // by default debugging is enabled
  const store = configureStore({
    reducer: rootReducer,
    middleware: () => [...middleware],
    preloadedState: preloadedState,
  });

  return store;
}

export const store = createStoreWithMiddleware();

registerEnhancedScheduleEndpoints();
registerEnhancedAppointmentEndpoints();
registerEnhancedLanguageEndpoints();
registerEnhancedCountryEndpoints();
registerEnhancedRegionEndpoints();
registerEnhancedCityEndpoints();
registerEnhancedOrganizationEndpoints();
registerEnhancedWorkingHoursEndpoints();
registerEnhancedAddressEndpoints();
registerEnhancedClinicEndpoints();
registerEnhancedUserEndpoints();
registerEnhancedCalendarEndpoints();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
