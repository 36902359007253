import { Popup } from 'devextreme-react';
import { useCallback, useMemo, useRef } from 'react';
import { useAppSelector } from '../../redux/store';
import {
  createOrganizationDialogVisibleSelector,
  setOrganizationToEdit,
  toggleCreateOrganizationDialog,
} from '../../redux/modules/organization';
import Form from 'devextreme-react/form';
import { ToolbarItem } from 'devextreme-react/popup';
import { useDispatch } from 'react-redux';
import { useCreateOrganizationMutation } from '../../api/medapp/organization.generated';
import { OrganizationForm } from './OrganizationForm';
import { OrganizationMultiLang } from '../../api';
import { useLanguage } from '../hooks/useLanguageHook';

export const CreateOrganizationDialog = () => {
  const formRef = useRef<Form>(null);
  const dispatch = useDispatch();
  const { language } = useLanguage();

  //create empty organization
  const organization: OrganizationMultiLang = useMemo(() => {
    return { name: [], code: '' };
  }, []);
  const showAddOrganizationDialog = useAppSelector(createOrganizationDialogVisibleSelector);

  const [createOrganization] = useCreateOrganizationMutation();

  const finish = useCallback(() => {
    const mappedOrganization = { ...organization };
    mappedOrganization.name = [{ value: organization.name as unknown as string, language: language }];

    createOrganization({ organizationMultiLangDto: mappedOrganization, language: language });
    dispatch(setOrganizationToEdit(undefined));
    dispatch(toggleCreateOrganizationDialog());
  }, [createOrganization, dispatch, language, organization]);

  const toggleDialog = useCallback(() => dispatch(toggleCreateOrganizationDialog()), [dispatch]);

  if (!showAddOrganizationDialog) return <div></div>;

  return (
    <div id={'CreateOrganizationDialogContainer'}>
      <Popup visible={showAddOrganizationDialog} resizeEnabled={true} showCloseButton={false}>
        <OrganizationForm reference={formRef} organization={organization} showTranslationsTab={false} />
        <ToolbarItem
          widget={'dxButton'}
          toolbar={'bottom'}
          location={'after'}
          options={{
            text: 'Confirm',
            elementAttr: { 'aria-label': 'confirm' },
            onClick: () => {
              const validationResult = formRef.current?.instance.validate();
              if (validationResult?.isValid) {
                finish();
              }
            },
          }}
        />
        <ToolbarItem
          widget={'dxButton'}
          toolbar={'bottom'}
          location={'after'}
          options={{
            text: 'Cancel',
            elementAttr: { 'aria-label': 'cancel' },
            onClick: () => toggleDialog(),
          }}
        />
      </Popup>
    </div>
  );
};
