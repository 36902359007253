import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import TreeView from 'devextreme-react/tree-view';
import './side-navigation-menu.scss';
import * as events from 'devextreme/events';
import { ChildNavigationItem, ParentNavigationItem, navigation } from '../../appRoutes';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import dxTreeView, { ItemClickEvent } from 'devextreme/ui/tree_view';
import { useAppSelector } from '../../redux/store';
import { isAuthenticatedSelector, rolesSelector } from '../../redux/modules/user';

interface SideNavigationMenuProps {
  children?: JSX.Element | JSX.Element[];
  selectedItemChanged: (e: ItemClickEvent<string>) => void;
  openMenu(e: events.EventObject): void;
  compactMode: boolean;
  onMenuReady: (e: events.EventInfo<dxTreeView<string>>) => void;
}

export default function SideNavigationMenu(props: React.PropsWithChildren<SideNavigationMenuProps>) {
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);
  const userRoles = useAppSelector(rolesSelector);
  const { children, selectedItemChanged, openMenu, compactMode, onMenuReady } = props;

  const normalizePath = useCallback(() => {
    const items: (ChildNavigationItem | ParentNavigationItem)[] = [];
    navigation(t).forEach((item) => {
      if (isAuthenticated && item.roles.some((role) => userRoles.includes(role))) {
        if (Object.hasOwn(item, 'items')) {
          const children = item.items as ChildNavigationItem[];
          const temp: ChildNavigationItem[] = [];
          children?.forEach((child) => {
            const hasAccess = child.roles.some((role) => userRoles.includes(role));
            if (hasAccess) {
              temp.push({ ...child });
            }
          });
          items.push({ ...item, items: temp });
        } else {
          if ('path' in item && !/^\//.test(item.path)) {
            item.path = `/${item.path}`;
          }
          items.push({ ...item });
        }
      } else if (item.roles.length === 0) {
        items.push({ ...item });
      }
    });

    return items;
  }, [isAuthenticated, t, userRoles]);

  //update nav menu items only when auth state or language changes
  const items = useMemo(() => normalizePath(), [normalizePath]);

  const treeViewRef = useRef<TreeView<string>>(null);
  const wrapperRef = useRef();
  const getWrapperRef = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (element: any) => {
      const prevElement = wrapperRef.current;
      if (prevElement) {
        events.off(prevElement, 'dxclick');
      }

      wrapperRef.current = element;
      events.on(element, 'dxclick', (e: events.EventObject) => {
        openMenu(e);
      });
    },
    [openMenu],
  );

  useEffect(() => {
    const treeView = treeViewRef && treeViewRef.current ? treeViewRef.current.instance : undefined;

    if (!treeView) {
      return;
    }

    if (pathname !== undefined) {
      treeView.selectItem(pathname);
    }

    if (compactMode) {
      treeView.collapseAll();
    }
  });

  return (
    <div
      className={'dx-swatch-additional side-navigation-menu'}
      ref={getWrapperRef}
      data-testid="side-navigation-menu-container"
    >
      {children}
      <div className={'menu-container'} data-testid="menu-container">
        <TreeView
          ref={treeViewRef}
          items={items}
          keyExpr={'path'}
          selectionMode={'single'}
          focusStateEnabled={false}
          expandEvent={'click'}
          onItemClick={selectedItemChanged}
          onContentReady={onMenuReady}
          width={'100%'}
          noDataText={''}
        />
      </div>
    </div>
  );
}
