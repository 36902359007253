import { enhancedApi } from './address.generated';

export const registerEnhancedAddressEndpoints = () => {
  enhancedApi.enhanceEndpoints({
    endpoints: {
      getAllAddressesMultilang: {
        providesTags: () => ['Address'],
      },
      updateAddress: {
        invalidatesTags: ['Address'],
      },
      createAddress: {
        invalidatesTags: ['Address'],
      },
    },
  });
};
