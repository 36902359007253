import './polyfills';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import config from 'devextreme/core/config';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import translationConfig from './translation/config';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { refreshAuthenticationState } from './redux/modules/user';
import { GlobalSettings } from './globalSettings';
import { loadMessages } from 'devextreme/localization';
import bgMessages from '../src/translation/bg.json';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);


const GlobalSettingsContext = React.createContext({});

GlobalSettings.readFromFile('settings.json')
  .then(() => {
    const globalSettingsInstance = GlobalSettings.getInstance();
    const keycloakClient = new Keycloak({
      realm: globalSettingsInstance.getKeycloakRealm(),
      url: globalSettingsInstance.getKeycloakAuthUrl(),
      clientId: globalSettingsInstance.getKeycloakClientId(),
    });

    //Global DevExtreme Settings
    config({
      editorStylingMode: 'underlined',
    });

    loadMessages(bgMessages);

    const root = createRoot(document.getElementById('root') as HTMLElement);
    root.render(
      <GlobalSettingsContext.Provider value={globalSettingsInstance}>
        <ReactKeycloakProvider
          authClient={keycloakClient}
          onTokens={() => {
            store.dispatch(refreshAuthenticationState(keycloakClient));
          }}
        >
          <Provider store={store}>
            <I18nextProvider i18n={translationConfig}>
              <Suspense fallback={'...loading'}>
                <App></App>
              </Suspense>
            </I18nextProvider>
          </Provider>
        </ReactKeycloakProvider>
      </GlobalSettingsContext.Provider>,
    );
  })
  .catch((err) => {
    const root = createRoot(document.getElementById('root') as HTMLElement);
    root.render(<MainContent />);
    // rethrow error to be visible in console
    throw err;
  });

const MainContent = (): JSX.Element => {
  return (
    <Provider store={store}>
      <I18nextProvider i18n={translationConfig}>
        <Suspense fallback={'...loading'}>
          <App />
        </Suspense>
      </I18nextProvider>
    </Provider>
  );
};
