import { Button } from 'devextreme-react';
import { custom as customDialog } from 'devextreme/ui/dialog';

enum INFO_RESPONSE {
  CLOSE,
}

interface InfoDialogOptions {
  message: string;
  onClose?: () => void | undefined;
}

/**
 * Creates an info dialog with a message and a close button
 */
export default function infoDialog({ message, onClose }: InfoDialogOptions): void {
  const infoDialog = customDialog({
    showTitle: false,
    messageHtml: message,
    buttons: [
      {
        text: 'Close',
        onClick: () => INFO_RESPONSE.CLOSE,
        elementAttr: { 'data-testid': 'infoDialog-confirm' },
      },
    ] as Button['props'][],
  });

  infoDialog.show().then((dialogResult: INFO_RESPONSE.CLOSE) => {
    /* istanbul ignore else */
    if (dialogResult === INFO_RESPONSE.CLOSE) {
      onClose?.();
    }
  });
}
