import { Middleware, MiddlewareAPI, isRejectedWithValue } from '@reduxjs/toolkit';
import notify from 'devextreme/ui/notify';

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    notify('Error: ' + JSON.stringify(action.payload), 'error', 15000);
  }

  return next(action);
};
