import instantsearch from 'instantsearch.js';
import { connectPagination, connectSearchBox } from 'instantsearch.js/es/connectors';
import historyRouter from 'instantsearch.js/es/lib/routers/history';
import { debounce } from './debounce';
import { indexName, searchClient } from './searchClient';

const instantSearchRouter = historyRouter();

export const search = instantsearch({
  searchClient,
  indexName: indexName,
});
const virtualSearchBox = connectSearchBox(() => {});
const virtualPagination = connectPagination(() => null);

/*
page needs one of these widgets: "pagination", "infiniteHits"

If you do not wish to display widgets but still want to support their search parameters, you can mount "virtual widgets" that don't render anything:

```
const virtualPagination = connectPagination(() => null);

search.addWidgets([
  virtualPagination({..}) 
*/
export const populateSearchWidgets = () => {
  search.addWidgets([
    // Mount a virtual search box to manipulate InstantSearch's `query` UI
    // state parameter.
    virtualSearchBox({}),
    virtualPagination({}),
  ]);
};

// Set the InstantSearch index UI state from external events.
export function setInstantSearchUiState(indexUiState: any) {
  search.setUiState((uiState) => ({
    ...uiState,
    [indexName]: {
      ...uiState[indexName],
      // We reset the page when the search state changes.
      page: 1,
      ...indexUiState,
    },
  }));
}

export const debouncedSetInstantSearchUiState = debounce(setInstantSearchUiState, 500);

// Build URLs that InstantSearch understands.
export function getInstantSearchUrl(indexUiState: any) {
  return search.createURL({ [indexName]: indexUiState });
}

// Return the InstantSearch index UI state.
export function getInstantSearchUiState() {
  const uiState = instantSearchRouter.read();

  return (uiState && uiState[indexName]) || {};
}
