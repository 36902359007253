import { medAppApi as api } from '../medappApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateAddress: build.mutation<UpdateAddressApiResponse, UpdateAddressApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/address/edit/${queryArg.addressId}`,
        method: 'PUT',
        body: queryArg.addressMultiLangDto,
      }),
    }),
    createAddress: build.mutation<CreateAddressApiResponse, CreateAddressApiArg>({
      query: (queryArg) => ({ url: `/api/v1/address/create`, method: 'POST', body: queryArg.addressMultiLangDto }),
    }),
    getAddressDetails: build.query<GetAddressDetailsApiResponse, GetAddressDetailsApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/address/details/${queryArg.addressId}`,
        params: { language: queryArg.language },
      }),
    }),
    getAllAddresses: build.query<GetAllAddressesApiResponse, GetAllAddressesApiArg>({
      query: (queryArg) => ({ url: `/api/v1/address/all`, params: { language: queryArg.language } }),
    }),
    getAllAddressesMultilang: build.query<GetAllAddressesMultilangApiResponse, GetAllAddressesMultilangApiArg>({
      query: () => ({ url: `/api/v1/address/all-multilingual` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type UpdateAddressApiResponse = /** status 200 OK */ object;
export type UpdateAddressApiArg = {
  addressId: number;
  addressMultiLangDto: AddressMultiLangDto;
};
export type CreateAddressApiResponse = /** status 200 OK */ object;
export type CreateAddressApiArg = {
  addressMultiLangDto: AddressMultiLangDto;
};
export type GetAddressDetailsApiResponse = /** status 200 OK */ ObjectRevisionDto[];
export type GetAddressDetailsApiArg = {
  addressId: number;
  language?: string;
};
export type GetAllAddressesApiResponse = /** status 200 OK */ AddressMultiLangDto[];
export type GetAllAddressesApiArg = {
  language: string;
};
export type GetAllAddressesMultilangApiResponse = /** status 200 OK */ AddressMultiLangDto[];
export type GetAllAddressesMultilangApiArg = void;
export type LocalizedStringDto = {
  language: string;
  value: string;
};
export type AddressMultiLangDto = {
  id?: number;
  streetNumber?: string;
  name: LocalizedStringDto[];
  floorNumber?: string;
  apartmentNumber?: string;
  latitude?: string;
  longitude?: string;
  postalCode?: string;
  notes?: LocalizedStringDto[];
  countryId: number;
  regionId?: number;
  cityId: number;
};
export type UserDto = {
  id?: number;
  firstName?: string;
  lastName?: string;
  username?: string;
  clinicIds?: number[];
  specialityIds?: number[];
  phoneNumber?: string;
  emailAddress?: string;
  address?: string;
  profilePicture?: string;
  type?: 'ADMIN' | 'MANAGER' | 'REGISTRATOR' | 'SPECIALIST' | 'ASSISTANT';
};
export type ObjectRevisionDto = {
  object?: object;
  revisionType?: string;
  revisionDate?: number;
  revisionId?: number;
  user?: UserDto;
};
export const {
  useUpdateAddressMutation,
  useCreateAddressMutation,
  useGetAddressDetailsQuery,
  useGetAllAddressesQuery,
  useGetAllAddressesMultilangQuery,
} = injectedRtkApi;
