import { medAppApi as api } from '../medappApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateCountry: build.mutation<UpdateCountryApiResponse, UpdateCountryApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/country/${queryArg.countryId}/edit`,
        method: 'PUT',
        body: queryArg.countryMultiLangDto,
      }),
    }),
    createCountry: build.mutation<CreateCountryApiResponse, CreateCountryApiArg>({
      query: (queryArg) => ({ url: `/api/v1/country/create`, method: 'POST', body: queryArg.countryMultiLangDto }),
    }),
    getCountryDetails: build.query<GetCountryDetailsApiResponse, GetCountryDetailsApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/country/details/${queryArg.countryId}`,
        params: { language: queryArg.language },
      }),
    }),
    getAllCountry: build.query<GetAllCountryApiResponse, GetAllCountryApiArg>({
      query: (queryArg) => ({ url: `/api/v1/country/all`, params: { language: queryArg.language } }),
    }),
    getAllCountryMultiLang: build.query<GetAllCountryMultiLangApiResponse, GetAllCountryMultiLangApiArg>({
      query: () => ({ url: `/api/v1/country/all-multilingual` }),
    }),
    deleteCountry: build.mutation<DeleteCountryApiResponse, DeleteCountryApiArg>({
      query: (queryArg) => ({ url: `/api/v1/country/${queryArg.countryId}/delete`, method: 'DELETE' }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type UpdateCountryApiResponse = /** status 200 OK */ object;
export type UpdateCountryApiArg = {
  countryId: number;
  countryMultiLangDto: CountryMultiLangDto;
};
export type CreateCountryApiResponse = /** status 200 OK */ object;
export type CreateCountryApiArg = {
  countryMultiLangDto: CountryMultiLangDto;
};
export type GetCountryDetailsApiResponse = /** status 200 OK */ ObjectRevisionDto[];
export type GetCountryDetailsApiArg = {
  countryId: number;
  language?: string;
};
export type GetAllCountryApiResponse = /** status 200 OK */ CountryMultiLangDto[];
export type GetAllCountryApiArg = {
  language: string;
};
export type GetAllCountryMultiLangApiResponse = /** status 200 OK */ CountryMultiLangDto[];
export type GetAllCountryMultiLangApiArg = void;
export type DeleteCountryApiResponse = /** status 200 OK */ object;
export type DeleteCountryApiArg = {
  countryId: number;
};
export type LocalizedStringDto = {
  language: string;
  value: string;
};
export type CountryMultiLangDto = {
  id?: number;
  name?: LocalizedStringDto[];
  code?: string;
};
export type UserDto = {
  id?: number;
  firstName?: string;
  lastName?: string;
  username?: string;
  clinicIds?: number[];
  specialityIds?: number[];
  phoneNumber?: string;
  emailAddress?: string;
  address?: string;
  profilePicture?: string;
  type?: 'ADMIN' | 'MANAGER' | 'REGISTRATOR' | 'SPECIALIST' | 'ASSISTANT';
};
export type ObjectRevisionDto = {
  object?: object;
  revisionType?: string;
  revisionDate?: number;
  revisionId?: number;
  user?: UserDto;
};
export const {
  useUpdateCountryMutation,
  useCreateCountryMutation,
  useGetCountryDetailsQuery,
  useGetAllCountryQuery,
  useGetAllCountryMultiLangQuery,
  useDeleteCountryMutation,
} = injectedRtkApi;
