import { medAppApi as api } from '../medappApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateSchedule: build.mutation<UpdateScheduleApiResponse, UpdateScheduleApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/schedule/edit/${queryArg.scheduleId}`,
        method: 'PUT',
        body: queryArg.scheduleDto,
        headers: { timeZone: queryArg.timeZone },
      }),
    }),
    createSchedule: build.mutation<CreateScheduleApiResponse, CreateScheduleApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/schedule/create`,
        method: 'POST',
        body: queryArg.scheduleDto,
        headers: { timeZone: queryArg.timeZone },
      }),
    }),
    getWorkingHoursBySchedule: build.query<GetWorkingHoursByScheduleApiResponse, GetWorkingHoursByScheduleApiArg>({
      query: (queryArg) => ({ url: `/api/v1/workinghours/${queryArg.scheduleId}` }),
    }),
    getScheduleByStartDateAndEndDate: build.query<
      GetScheduleByStartDateAndEndDateApiResponse,
      GetScheduleByStartDateAndEndDateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/schedule/${queryArg.userId}`,
        headers: { timeZone: queryArg.timeZone },
        params: { start_date: queryArg.startDate, end_date: queryArg.endDate },
      }),
    }),
    getSchedulesByUserId: build.query<GetSchedulesByUserIdApiResponse, GetSchedulesByUserIdApiArg>({
      query: (queryArg) => ({ url: `/api/v1/schedule/user/${queryArg.userId}` }),
    }),
    getSchedulesByUserIdAndWeek: build.query<GetSchedulesByUserIdAndWeekApiResponse, GetSchedulesByUserIdAndWeekApiArg>(
      {
        query: (queryArg) => ({
          url: `/api/v1/schedule/user/${queryArg.userId}/week/${queryArg.week}`,
          headers: { timeZone: queryArg.timeZone },
        }),
      },
    ),
    getSchedulesByUserIdAndMonth: build.query<
      GetSchedulesByUserIdAndMonthApiResponse,
      GetSchedulesByUserIdAndMonthApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/schedule/user/${queryArg.userId}/month/${queryArg.month}`,
        headers: { timeZone: queryArg.timeZone },
      }),
    }),
    getSchedulesByUserIdAndDate: build.query<GetSchedulesByUserIdAndDateApiResponse, GetSchedulesByUserIdAndDateApiArg>(
      {
        query: (queryArg) => ({
          url: `/api/v1/schedule/user/${queryArg.userId}/date/${queryArg.date}`,
          headers: { timeZone: queryArg.timeZone },
        }),
      },
    ),
    getScheduleDetails: build.query<GetScheduleDetailsApiResponse, GetScheduleDetailsApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/schedule/details/${queryArg.scheduleId}`,
        params: { language: queryArg.language },
      }),
    }),
    getAllSchedules: build.query<GetAllSchedulesApiResponse, GetAllSchedulesApiArg>({
      query: () => ({ url: `/api/v1/schedule/all` }),
    }),
    deleteSchedules: build.mutation<DeleteSchedulesApiResponse, DeleteSchedulesApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/schedule/delete`,
        method: 'DELETE',
        params: { scheduleIds: queryArg.scheduleIds },
      }),
    }),
    deleteSchedule: build.mutation<DeleteScheduleApiResponse, DeleteScheduleApiArg>({
      query: (queryArg) => ({ url: `/api/v1/schedule/delete/${queryArg.scheduleId}`, method: 'DELETE' }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type UpdateScheduleApiResponse = /** status 200 OK */ ScheduleDto;
export type UpdateScheduleApiArg = {
  scheduleId: number;
  timeZone?: string;
  scheduleDto: ScheduleDto;
};
export type CreateScheduleApiResponse = /** status 200 OK */ ScheduleDto;
export type CreateScheduleApiArg = {
  timeZone?: string;
  scheduleDto: ScheduleDto;
};
export type GetWorkingHoursByScheduleApiResponse = /** status 200 OK */ WorkingHoursDto[];
export type GetWorkingHoursByScheduleApiArg = {
  scheduleId: number;
};
export type GetScheduleByStartDateAndEndDateApiResponse = /** status 200 OK */ ScheduleDto[];
export type GetScheduleByStartDateAndEndDateApiArg = {
  userId: number;
  startDate?: string;
  endDate?: string;
  timeZone?: string;
};
export type GetSchedulesByUserIdApiResponse = /** status 200 OK */ ScheduleDto[];
export type GetSchedulesByUserIdApiArg = {
  userId: number;
};
export type GetSchedulesByUserIdAndWeekApiResponse = /** status 200 OK */ ScheduleDto[];
export type GetSchedulesByUserIdAndWeekApiArg = {
  userId: number;
  week: number;
  timeZone?: string;
};
export type GetSchedulesByUserIdAndMonthApiResponse = /** status 200 OK */ ScheduleDto[];
export type GetSchedulesByUserIdAndMonthApiArg = {
  userId: number;
  month: number;
  timeZone?: string;
};
export type GetSchedulesByUserIdAndDateApiResponse = /** status 200 OK */ ScheduleDto[];
export type GetSchedulesByUserIdAndDateApiArg = {
  userId: number;
  date: string;
  timeZone?: string;
};
export type GetScheduleDetailsApiResponse = /** status 200 OK */ ObjectRevisionDto[];
export type GetScheduleDetailsApiArg = {
  scheduleId: number;
  language?: string;
};
export type GetAllSchedulesApiResponse = /** status 200 OK */ ScheduleDto[];
export type GetAllSchedulesApiArg = void;
export type DeleteSchedulesApiResponse = unknown;
export type DeleteSchedulesApiArg = {
  scheduleIds: number[];
};
export type DeleteScheduleApiResponse = unknown;
export type DeleteScheduleApiArg = {
  scheduleId: number;
};
export type WorkingHoursDto = {
  id?: number;
  clinicId?: number;
  scheduleId?: number;
  dayOfWeek?: 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';
  startTime?: string;
  endTime?: string;
  breakStartTime?: string;
  breakEndTime?: string;
};
export type ScheduleDto = {
  id?: number;
  userId: number;
  clinicId: number;
  startTime: string;
  endTime: string;
  usualInitialAppointmentDuration?: number;
  usualSecondaryAppointmentDuration?: number;
  intervalBetweenAppointments?: number;
  workingHours?: WorkingHoursDto[];
};
export type UserDto = {
  id?: number;
  firstName?: string;
  lastName?: string;
  username?: string;
  clinicIds?: number[];
  specialityIds?: number[];
  phoneNumber?: string;
  emailAddress?: string;
  address?: string;
  profilePicture?: string;
  type?: 'ADMIN' | 'MANAGER' | 'REGISTRATOR' | 'SPECIALIST' | 'ASSISTANT';
};
export type ObjectRevisionDto = {
  object?: object;
  revisionType?: string;
  revisionDate?: number;
  revisionId?: number;
  user?: UserDto;
};
export const {
  useUpdateScheduleMutation,
  useCreateScheduleMutation,
  useGetWorkingHoursByScheduleQuery,
  useGetScheduleByStartDateAndEndDateQuery,
  useGetSchedulesByUserIdQuery,
  useGetSchedulesByUserIdAndWeekQuery,
  useGetSchedulesByUserIdAndMonthQuery,
  useGetSchedulesByUserIdAndDateQuery,
  useGetScheduleDetailsQuery,
  useGetAllSchedulesQuery,
  useDeleteSchedulesMutation,
  useDeleteScheduleMutation,
} = injectedRtkApi;
