import notify from 'devextreme/ui/notify';
import { ScheduleMapped } from '../../api/dataMapper';
import { DateTime, Interval } from 'luxon';
import { AppointmentDTO } from '../../api/medapp/generated';
import { WorkingHoursDto } from '../../api/medapp/workingHours.generated';
import i18n from '../../translation/config';

export const holidays = [new Date(2023, 3, 25)];

const notifyDisableDate = () => {
  notify('Cannot create or move an appointment/event to disabled time/date regions.', 'warning', 1000);
};

export const applyDisableDatesToDateEditors = (form: any) => {
  const startDateEditor = form.getEditor('startDate');
  startDateEditor.option('disabledDates', holidays);

  const endDateEditor = form.getEditor('endDate');
  endDateEditor.option('disabledDates', holidays);
};

export const onAppointmentFormOpening = (e: any, freeTimeSlots: ScheduleMapped[]) => {
  const startDate = new Date(e.appointmentData.startDate);
  if (!Utils.isValidAppointmentDate(startDate, freeTimeSlots)) {
    e.cancel = true;
    notifyDisableDate();
  }
  applyDisableDatesToDateEditors(e.form);
};

export const onAppointmentAdding = (e: any, freeTimeSlots: ScheduleMapped[]) => {
  const isValidAppointment =
    Utils.isValidAppointment(e.component, e.appointmentData, freeTimeSlots) &&
    Utils.isAllowedTime(e.appointmentData.startDate, e.appointmentData.endDate, freeTimeSlots);
  if (!isValidAppointment) {
    e.cancel = true;
    notifyDisableDate();
  }
};

export const onAppointmentUpdating = (e: any, freeTimeSlots: ScheduleMapped[]) => {
  const isValidAppointment = Utils.isValidAppointment(e.component, e.newData, freeTimeSlots);
  if (!isValidAppointment) {
    e.cancel = true;
    notifyDisableDate();
  }
};

export default class Utils {
  static isHoliday(date: { toLocaleDateString: () => any }) {
    const localeDate = date.toLocaleDateString();
    return (
      holidays.filter((holiday: { toLocaleDateString: () => any }) => holiday.toLocaleDateString() === localeDate)
        .length > 0
    );
  }

  static isWeekend(date: { getDay: () => any }) {
    const day = date.getDay();
    return day === 0 || day === 6;
  }

  static isDisabledDate(date: Date) {
    return Utils.isHoliday(date);
  }

  static isEnabledTime(currentDataCellDate: Date, workingHours: WorkingHoursDto[], intervals: Interval[]) {
    const cellDateHours = currentDataCellDate.getHours();
    const cellDateMinutes = currentDataCellDate.getMinutes();
    const cellDate = DateTime.fromISO(currentDataCellDate.toISOString());
    //setLocale first, otherwise they can be returned in other languages, based on the device settings...
    const cellWeekDay = cellDate.setLocale('en').weekdayLong.toUpperCase();

    const currentInterval = intervals.find((interval) => interval.contains(cellDate));
    const workingHoursOnlyForInterval: WorkingHoursDto[] = [];
    if (currentInterval) {
      workingHours.forEach((wh) => {
        if (currentInterval.contains(DateTime.fromISO(String(wh.startTime)))) workingHoursOnlyForInterval.push(wh);
      });
    }

    return workingHoursOnlyForInterval.some((workingHour) => {
      //working hour start time
      const whStart = DateTime.fromISO(String(workingHour.startTime));
      const startHour = whStart.hour;
      const startMinutes = whStart.minute;
      //working hour end time
      const whEnd = DateTime.fromISO(String(workingHour.endTime));
      const endHour = whEnd.hour;
      const endMinutes = whEnd.minute;

      if (cellWeekDay === workingHour.dayOfWeek && cellDateHours >= startHour && cellDateHours <= endHour) {
        if (cellDateHours === startHour) {
          if (cellDateMinutes >= startMinutes) {
            return true;
          } else return false;
        }
        if (cellDateHours === endHour) {
          //fix for disabled cells, cellDateMinutes is normally 00 or 30 (min), so in order to disable next cell
          //when someone works for XX:30, we have to remove 1 min from the end time and compare...
          if (cellDateMinutes <= endMinutes - 1) {
            return true;
          } else return false;
        }
        return true;
      }
      return false;
    });
  }

  static isAllowedTime(startDate: Date, endDate: Date, freeTimeSlots: ScheduleMapped[]) {
    const formStartDate = DateTime.fromISO(startDate.toISOString());
    const formEndDate = DateTime.fromISO(endDate.toISOString());
    return freeTimeSlots.some((timeSlot) => {
      const timeSlotStartDate = DateTime.fromISO(timeSlot.startDate.toISOString());
      const timeSlotEndDate = DateTime.fromISO(timeSlot.endDate.toISOString());
      if (
        timeSlotStartDate.hasSame(formStartDate, 'day') &&
        timeSlotStartDate.hasSame(formStartDate, 'month') &&
        timeSlotStartDate.hasSame(formStartDate, 'year')
      ) {
        if (
          formStartDate.valueOf() >= timeSlotStartDate.valueOf() &&
          formEndDate.valueOf() <= timeSlotEndDate.valueOf()
        )
          return true;
      }
      return false;
    });
  }

  static isValidAppointmentDate(date: Date, freeTimeSlots: ScheduleMapped[]) {
    return (
      !Utils.isHoliday(date) && !Utils.isDisabledDate(date)
      //Utils.isEnabledTime(date, freeTimeSlots) &&
      //!Utils.isWeekend(date)
    );
  }

  static isValidAppointmentInterval(
    startDate: Date,
    endDate: Date,
    cellDuration: number,
    freeTimeSlots: ScheduleMapped[],
  ) {
    const edgeEndDate = new Date(endDate.getTime() - 1);

    if (!Utils.isValidAppointmentDate(edgeEndDate, freeTimeSlots)) {
      return false;
    }

    const durationInMs = cellDuration * 60 * 1000;
    const date = startDate;
    while (date <= endDate) {
      if (!Utils.isValidAppointmentDate(date, freeTimeSlots)) {
        return false;
      }
      const newDateTime = date.getTime() + durationInMs - 1;
      date.setTime(newDateTime);
    }

    return true;
  }

  static isValidAppointment(
    component: { option: (arg0: string) => any },
    appointmentData: { startDate: string | number | Date; endDate: string | number | Date },
    freeTimeSlots: ScheduleMapped[],
  ) {
    const startDate = new Date(appointmentData.startDate);
    const endDate = new Date(appointmentData.endDate);
    const cellDuration = component.option('cellDuration');
    return Utils.isValidAppointmentInterval(startDate, endDate, cellDuration, freeTimeSlots);
  }
}

//https://supportcenter.devexpress.com/ticket/details/t1086863/dynamic-filtering-on-a-scheduler-calendar
export const constructFilterExpression = (key: string, entries: string[]) => {
  const filterExpression: unknown[] = [];
  entries.forEach((entry) => filterExpression.push([key, 'contains', entry], 'or'));
  return filterExpression;
};

export const getTranslatedAppointmentType = (appointmentType: AppointmentDTO.appointmentType) => {
  switch (appointmentType) {
    case AppointmentDTO.appointmentType.INITIAL:
      return i18n.t('appointmentTypes.INITIAL');
    case AppointmentDTO.appointmentType.SECONDARY:
      return i18n.t('appointmentTypes.SECONDARY');
    case AppointmentDTO.appointmentType.OPEN:
      return i18n.t('appointmentTypes.OPEN');
    case AppointmentDTO.appointmentType.INSURANCE:
      return i18n.t('appointmentTypes.INSURANCE');
    case AppointmentDTO.appointmentType.OTHER:
      return i18n.t('appointmentTypes.OTHER');
    case AppointmentDTO.appointmentType.ABSENCE:
      return i18n.t('appointmentTypes.ABSENCE');
  }
};
