import { enhancedApi } from './organization.generated';

export const registerEnhancedOrganizationEndpoints = () => {
  enhancedApi.enhanceEndpoints({
    endpoints: {
      getAllOrganizationsMultiLang: {
        providesTags: () => ['Organization'],
      },
      updateOrganization: {
        invalidatesTags: ['Organization'],
      },
      createOrganization: {
        invalidatesTags: ['Organization'],
      },
    },
  });
};
