import { OpenAPI_MEDAPP } from './api';

export class GlobalSettings {
  // Backend REST URLS
  private medappBaseUrl = '';
  // KEYCLOAK
  private keycloakRealm = '';
  private keycloakAuthUrl = '';
  private keycloakClientId = '';
  private minioUrl = '';

  //singleton class instance
  private static instance: GlobalSettings;

  //singleton instance
  public static getInstance(): GlobalSettings {
    if (!GlobalSettings.instance) {
      throw new TypeError('GlobalSettings object is not initialized, please load the app settings first!');
    }

    return GlobalSettings.instance;
  }

  public getMedappBaseUrl(): string {
    return this.medappBaseUrl;
  }

  public getKeycloakRealm(): string {
    return this.keycloakRealm;
  }

  public getKeycloakAuthUrl(): string {
    return this.keycloakAuthUrl;
  }

  public getKeycloakClientId(): string {
    return this.keycloakClientId;
  }

  public getMinioUrl() {
    return this.minioUrl;
  }

  public setMedappBaseUrl(value: string) {
    this.medappBaseUrl = value;
  }

  public setKeycloakRealm(value: string) {
    this.keycloakRealm = value;
  }

  public setKeycloakAuthUrl(value: string) {
    this.keycloakAuthUrl = value;
  }

  public setKeycloakClientId(value: string) {
    this.keycloakClientId = value;
  }

  public setMinioUrl(value: string) {
    this.minioUrl = value;
  }

  // reads and validates values from a settings object
  private static async readFromJson(settings: Record<string, string | undefined>): Promise<void> {
    const requiredSettings = [
      'REACT_APP_MEDAPP_REST_API',
      'REACT_APP_KEYCLOAK_REALM',
      'REACT_APP_KEYCLOAK_URL',
      'REACT_APP_KEYCLOAK_CLIENT_ID',
      'REACT_APP_MINIO_URL',
    ];
    //check for required settings
    requiredSettings.forEach((setting) => {
      if (!(setting in settings)) {
        throw TypeError(`Setting ${setting} is required`);
      }
      if (settings[setting] === undefined || typeof settings[setting] !== 'string' || settings[setting] === '')
        throw TypeError(`Setting ${setting} must not be empty`);
    });

    //create and setup the settings object
    GlobalSettings.instance = new GlobalSettings();
    const globalSettings = GlobalSettings.getInstance();
    globalSettings.setMedappBaseUrl(settings['REACT_APP_MEDAPP_REST_API'] ?? '');
    globalSettings.setMinioUrl(settings['REACT_APP_MINIO_URL'] ?? '');

    // Keycloak
    globalSettings.setKeycloakRealm(settings['REACT_APP_KEYCLOAK_REALM'] ?? '');
    globalSettings.setKeycloakAuthUrl(settings['REACT_APP_KEYCLOAK_URL'] ?? '');
    globalSettings.setKeycloakClientId(settings['REACT_APP_KEYCLOAK_CLIENT_ID'] ?? '');

    OpenAPI_MEDAPP.BASE = globalSettings.getMedappBaseUrl();

    return Promise.resolve();
  }

  /**
   * fetches the provided filename and extracts global settings
   */
  //overload for more declarative fn signature and more ways for app setup and testing...
  public static async readFromFile(file: string): Promise<void>;
  public static async readFromFile(file: Record<string, string | undefined>): Promise<void>;
  public static async readFromFile(file: unknown): Promise<void> {
    let parsed;
    if (typeof file === 'string') {
      const response = await fetch(file);
      parsed = await response.json();
    }
    return GlobalSettings.readFromJson(parsed || file);
  }
}
