import { medAppApi as api } from '../medappApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateOrganization: build.mutation<UpdateOrganizationApiResponse, UpdateOrganizationApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/organization/edit/${queryArg.organizationId}`,
        method: 'PUT',
        body: queryArg.organizationMultiLangDto,
      }),
    }),
    createOrganization: build.mutation<CreateOrganizationApiResponse, CreateOrganizationApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/organization/create`,
        method: 'POST',
        body: queryArg.organizationMultiLangDto,
        params: { language: queryArg.language },
      }),
    }),
    getOrganizationDetails: build.query<GetOrganizationDetailsApiResponse, GetOrganizationDetailsApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/organization/details/${queryArg.organizationId}`,
        params: { language: queryArg.language },
      }),
    }),
    getAllOrganizations: build.query<GetAllOrganizationsApiResponse, GetAllOrganizationsApiArg>({
      query: (queryArg) => ({ url: `/api/v1/organization/all`, params: { language: queryArg.language } }),
    }),
    getAllOrganizationsMultiLang: build.query<
      GetAllOrganizationsMultiLangApiResponse,
      GetAllOrganizationsMultiLangApiArg
    >({
      query: () => ({ url: `/api/v1/organization/all-multilingual` }),
    }),
    deleteOrganization: build.mutation<DeleteOrganizationApiResponse, DeleteOrganizationApiArg>({
      query: (queryArg) => ({ url: `/api/v1/organization/delete/${queryArg.organizationId}`, method: 'DELETE' }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type UpdateOrganizationApiResponse = /** status 200 OK */ object;
export type UpdateOrganizationApiArg = {
  organizationId: number;
  organizationMultiLangDto: OrganizationMultiLangDto;
};
export type CreateOrganizationApiResponse = /** status 200 OK */ object;
export type CreateOrganizationApiArg = {
  language: string;
  organizationMultiLangDto: OrganizationMultiLangDto;
};
export type GetOrganizationDetailsApiResponse = /** status 200 OK */ ObjectRevisionDto[];
export type GetOrganizationDetailsApiArg = {
  organizationId: number;
  language?: string;
};
export type GetAllOrganizationsApiResponse = /** status 200 OK */ OrganizationDto[];
export type GetAllOrganizationsApiArg = {
  language: string;
};
export type GetAllOrganizationsMultiLangApiResponse = /** status 200 OK */ OrganizationMultiLangDto[];
export type GetAllOrganizationsMultiLangApiArg = void;
export type DeleteOrganizationApiResponse = /** status 200 OK */ object;
export type DeleteOrganizationApiArg = {
  organizationId: number;
};
export type LocalizedStringDto = {
  language: string;
  value: string;
};
export type OrganizationMultiLangDto = {
  id?: number;
  name: LocalizedStringDto[];
  code: string;
  phoneNumber?: string;
  clinicsIds?: number[];
  website?: string;
  mainLanguageId?: number;
  supportedLanguageIds?: number[];
  logoUrl?: string;
  coverPhotoUrl?: string;
};
export type UserDto = {
  id?: number;
  firstName?: string;
  lastName?: string;
  username?: string;
  clinicIds?: number[];
  specialityIds?: number[];
  phoneNumber?: string;
  emailAddress?: string;
  address?: string;
  profilePicture?: string;
  type?: 'ADMIN' | 'MANAGER' | 'REGISTRATOR' | 'SPECIALIST' | 'ASSISTANT';
};
export type ObjectRevisionDto = {
  object?: object;
  revisionType?: string;
  revisionDate?: number;
  revisionId?: number;
  user?: UserDto;
};
export type OrganizationDto = {
  id: number;
  code: string;
  phoneNumber?: string;
  name: string;
  logoUrl?: string;
  coverPhotoUrl?: string;
  website?: string;
};
export const {
  useUpdateOrganizationMutation,
  useCreateOrganizationMutation,
  useGetOrganizationDetailsQuery,
  useGetAllOrganizationsQuery,
  useGetAllOrganizationsMultiLangQuery,
  useDeleteOrganizationMutation,
} = injectedRtkApi;
