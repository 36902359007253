import { DataGrid } from 'devextreme-react';
import { LocalizedStringDTO } from '../../api/medapp/generated';
import { Column, Editing } from 'devextreme-react/data-grid';

export interface LanguageDetailGridProps {
  names: LocalizedStringDTO[];
  onRowUpdating: (oldData: any, newData: any) => void;
}

export const LanguageDetailGrid = (props: LanguageDetailGridProps) => {
  return (
    <DataGrid
      dataSource={props.names}
      onRowUpdating={({ oldData, newData }) => {
        props.onRowUpdating(oldData, newData);
      }}
    >
      <Editing allowUpdating={true} />
      <Column dataField={'language'} allowEditing={false} />
      <Column dataField={'value'} />
    </DataGrid>
  );
};
