import { LoadPanel, Popup, SelectBox } from 'devextreme-react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useAppSelector } from '../../redux/store';
import Form, { SimpleItem, Tab, TabPanelOptions, TabbedItem } from 'devextreme-react/form';
import { ToolbarItem } from 'devextreme-react/popup';
import { useDispatch } from 'react-redux';
import { createAddressDialogVisibleSelector } from '../../redux/modules/address';
import { toggleCreateAddressDialog } from '../../redux/modules/address';
import { AddressMultiLang } from '../../api';
import { useCreateAddressMutation } from '../../api/medapp/address.generated';
import { LanguageDetailGrid } from '../LanguageDetailGrid/LanguageDetailGrid';
import { getTranslatedValue, updateObjectLanguage } from '../helpers';
import { selectAllCities } from '../../redux/modules/city';
import { useGetAllCountryMultiLangQuery } from '../../api/medapp/country.generated';
import { useLanguage } from '../hooks/useLanguageHook';

const defaultEditorOptions = { stylingMode: 'outlined' };

export const CreateAddressDialog = () => {
  const { language } = useLanguage();

  const formRef = useRef<Form>(null);
  const dispatch = useDispatch();

  const showAddAddressDialog = useAppSelector(createAddressDialogVisibleSelector);

  const [createAddress] = useCreateAddressMutation();

  const [cityId, setCityId] = useState(0);
  const [countryId, setCountryId] = useState(0);

  const allCities = useAppSelector(selectAllCities);
  const { currentData: allCountry, isFetching: isFetchingCountries } = useGetAllCountryMultiLangQuery();

  const address: AddressMultiLang = useMemo(() => {
    return {
      name: [],
      street: [
        { language: 'en', value: '' },
        { language: 'bg', value: '' },
      ],
      postalCode: '',
      notes: [
        { language: 'en', value: '' },
        { language: 'bg', value: '' },
      ],
      countryId: countryId,
      cityId: cityId,
      regionId: 0,
    };
  }, [cityId, countryId]);

  const finish = useCallback(() => {
    createAddress({ addressMultiLangDto: address });
    setCityId(0);
    setCountryId(0);
    dispatch(toggleCreateAddressDialog());
  }, [createAddress, address, dispatch]);

  const toggleDialog = useCallback(() => dispatch(toggleCreateAddressDialog()), [dispatch]);

  const updateAddressTranslations = useCallback(
    (oldData: any, newData: any) => {
      updateObjectLanguage(address, oldData.language, newData.value);
    },
    [address],
  );

  const LanguageDetailGridRenderer = useCallback(() => {
    return (
      <LanguageDetailGrid
        names={address?.name ?? []}
        onRowUpdating={(oldData, newData) => {
          updateAddressTranslations(oldData, newData);
        }}
      />
    );
  }, [address?.name, updateAddressTranslations]);

  const LanguageNotesDetailGridRenderer = useCallback(() => {
    return (
      <LanguageDetailGrid
        names={address?.notes ?? []}
        onRowUpdating={(oldData, newData) => {
          updateAddressTranslations(oldData, newData);
        }}
      />
    );
  }, [address?.notes, updateAddressTranslations]);

  const countryRenderer = useCallback(() => {
    return (
      <SelectBox
        dataSource={allCountry}
        valueExpr={'id'}
        value={countryId}
        displayExpr={(data) => getTranslatedValue(data.name, language)}
        onValueChanged={({ value }) => {
          setCountryId(value);
        }}
      />
    );
  }, [allCountry, countryId, language]);

  const cityRenderer = useCallback(() => {
    return (
      <SelectBox
        dataSource={allCities}
        valueExpr={'id'}
        value={cityId}
        displayExpr={(data) => getTranslatedValue(data.name, language)}
        onValueChanged={({ value }) => {
          setCityId(value);
        }}
      />
    );
  }, [allCities, cityId, language]);

  if (!showAddAddressDialog) return <div></div>;

  return (
    <div id={'CreateClinicDialogContainer'}>
      <LoadPanel visible={isFetchingCountries} />
      <Popup visible={showAddAddressDialog} resizeEnabled={true} showCloseButton={false}>
        <Form id="EditAddressForm" ref={formRef} formData={address} scrollingEnabled={true}>
          <TabbedItem>
            <TabPanelOptions deferRendering={false} />
            <Tab title={'INFO'} colCount={2}>
              <SimpleItem dataField={'countryId'} editorOptions={defaultEditorOptions} render={countryRenderer} />
              <SimpleItem dataField={'cityId'} editorOptions={defaultEditorOptions} render={cityRenderer} />
              <SimpleItem dataField={'streetNumber'} editorType="dxNumberBox" editorOptions={defaultEditorOptions} />
              <SimpleItem dataField={'apartmentNumber'} editorOptions={defaultEditorOptions} />
              <SimpleItem dataField={'floorNumber'} editorOptions={defaultEditorOptions} />
              <SimpleItem dataField={'latitude'} editorOptions={defaultEditorOptions} />
              <SimpleItem dataField={'longitude'} editorOptions={defaultEditorOptions} />
              <SimpleItem dataField={'postalCode'} editorOptions={defaultEditorOptions} />
            </Tab>
            <Tab title={'TRANSLATIONS-STREET-NAMES'}>
              <SimpleItem render={LanguageDetailGridRenderer} />
            </Tab>
            <Tab title={'TRANSLATIONS-NOTES'}>
              <SimpleItem render={LanguageNotesDetailGridRenderer} />
            </Tab>
          </TabbedItem>
        </Form>
        <ToolbarItem
          widget={'dxButton'}
          toolbar={'bottom'}
          location={'after'}
          options={{
            text: 'Confirm',
            elementAttr: { 'aria-label': 'confirm' },
            onClick: finish,
          }}
        />
        <ToolbarItem
          widget={'dxButton'}
          toolbar={'bottom'}
          location={'after'}
          options={{
            text: 'Cancel',
            elementAttr: { 'aria-label': 'cancel' },
            onClick: toggleDialog,
          }}
        />
      </Popup>
    </div>
  );
};
