import { enhancedApi } from './schedule.generated';

export const registerEnhancedScheduleEndpoints = () => {
  enhancedApi.enhanceEndpoints({
    endpoints: {
      getAllSchedules: {
        providesTags: () => ['Schedule'],
      },
      createSchedule: {
        invalidatesTags: ['Schedule'],
      },
      updateSchedule: {
        invalidatesTags: ['Schedule'],
      },
      deleteSchedule: {
        invalidatesTags: ['Schedule'],
      },
      getScheduleDetails: {
        providesTags: ['Schedule'],
      },
    },
  });
};
