import { LoadPanel, Popup, ScrollView } from 'devextreme-react';
import { useCallback, useRef } from 'react';
import { useAppSelector } from '../../redux/store';
import { clinicToEditSelector, setClinicToEdit } from '../../redux/modules/clinic';
import Form, { SimpleItem, Tab, TabPanelOptions, TabbedItem } from 'devextreme-react/form';
import { LanguageDetailGrid } from '../LanguageDetailGrid/LanguageDetailGrid';
import { ToolbarItem } from 'devextreme-react/popup';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { LocalizedStringDto, useUpdateClinicMutation, } from '../../api/medapp/clinic.generated';
import { updateObjectLanguage } from '../helpers';

const defaultEditorOptions = { stylingMode: 'outlined' };

export const EditClinicDialog = () => {
  const formRef = useRef<Form>(null);
  const dispatch = useDispatch();
  const clinicToEdit = cloneDeep(useAppSelector(clinicToEditSelector));

  const cancelEditing = useCallback(() => {
    dispatch(setClinicToEdit(undefined));
  }, [dispatch]);

  const updateClinicTranslations =(oldData: LocalizedStringDto, newData: { value: string }) => {
    updateObjectLanguage(clinicToEdit, oldData.language, newData.value);
  };

  const [updateClinic] = useUpdateClinicMutation();

  const finish = useCallback(() => {
    if (clinicToEdit?.id && clinicToEdit) {
        updateClinic({
        clinicId: clinicToEdit.id,
        clinicMultiLangDto: clinicToEdit,
      });
      dispatch(setClinicToEdit(undefined));
    }
  }, [dispatch, clinicToEdit, updateClinic]);

  if (!clinicToEdit) return <div></div>;

  return (
    <div id={'EditClinicDialogContainer'}>
      <Popup visible={clinicToEdit !== undefined} resizeEnabled={true} showCloseButton={false}>
        <ScrollView>
          <LoadPanel
            visible={true}
            position={{ my: 'center', at: 'center', of: `#EditClinicForm` }}
            shading={true}
            shadingColor={'rgba(0,0,0,.32)'}
            height="100%"
          />
          <Form id="ClinicForm" ref={formRef} formData={clinicToEdit}>
            <TabbedItem>
              <TabPanelOptions deferRendering={false} />
              <Tab title={'CLINIC-NAME-TRANSLATIONS'}>
                <SimpleItem
                  render={() => (
                    <LanguageDetailGrid
                      names={clinicToEdit?.name ?? []}
                      onRowUpdating={(oldData, newData) => {
                        updateClinicTranslations(oldData, newData);
                      }}
                    />
                  )}
                />
              </Tab>
              <Tab title={'INFO'} colCount={2}>
                <SimpleItem dataField={'phoneNumber'} editorOptions={defaultEditorOptions} />
                <SimpleItem dataField={'logoUrl'} editorOptions={defaultEditorOptions} />
                <SimpleItem dataField={'coverPhotoUrl'} editorOptions={defaultEditorOptions} />
              </Tab>
            </TabbedItem>
          </Form>
        </ScrollView>
        <ToolbarItem
          widget={'dxButton'}
          toolbar={'bottom'}
          location={'after'}
          options={{
            text: 'Confirm',
            elementAttr: { 'aria-label': 'confirm' },
            onClick: () => {
              const validationResult = formRef.current?.instance.validate();
              if (validationResult?.isValid) {
                finish();
              }
            },
          }}
        />
        <ToolbarItem
          widget={'dxButton'}
          toolbar={'bottom'}
          location={'after'}
          options={{
            text: 'Cancel',
            elementAttr: { 'aria-label': 'cancel' },
            onClick: () => cancelEditing(),
          }}
        />
      </Popup>
    </div>
  );
};
