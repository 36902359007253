import { Appointment } from '.';
import { DateTime } from 'luxon';
import { LocalizedStringDto } from './medapp/country.generated';
import { LocalizedStringDTO, Schedule } from './medapp/generated';

// This Type Definition allows to check if String is key or subkey of an object.
export type RecursiveKeyOf<TObj extends Record<string, unknown>> = {
  [TKey in keyof TObj & (string | number)]: TObj[TKey] extends Record<string, unknown>
    ? `${TKey}` | `${TKey}.${RecursiveKeyOf<TObj[TKey]>}`
    : `${TKey}`;
}[keyof TObj & (string | number)];

export type ScheduleMapped = Omit<Schedule, ''> & {
  title?: string;
  startDate: Date;
  endDate: Date;
};

export type AppointmentMapped = Omit<Appointment, ''> & {
  startDate: Date;
  endDate: Date;
};

//prepared fn for parsing data to human readable style if needed...
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function parseDate(s: string): Date | undefined {
  const d = DateTime.fromISO(s).setZone('utc', { keepLocalTime: true });
  return d.isValid ? d.toJSDate() : undefined;
}

export function mapScheduleFromApi(apiData: Schedule): ScheduleMapped {
  return {
    ...apiData,
    title: 'some title',
    startDate: new Date(apiData?.startTime ?? new Date()),
    endDate: new Date(apiData?.endTime ?? new Date()),
  };
}

export function mapAppointmentFromApi(apiData: Appointment): AppointmentMapped {
  return {
    ...apiData,
    startDate: new Date(apiData?.startAt ?? new Date()),
    endDate: new Date(apiData?.endAt ?? new Date()),
  };
}

export function extractNames(names: LocalizedStringDto[]): Record<string, string> {
  const pairs: Record<string, string> = {};
  names.forEach((localizedString: LocalizedStringDTO) => (pairs[localizedString.language] = localizedString.value));
  return pairs;
}
