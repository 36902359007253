import { medAppApi as api } from '../medappApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateLanguage: build.mutation<UpdateLanguageApiResponse, UpdateLanguageApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/language/${queryArg.languageId}/edit`,
        method: 'PUT',
        body: queryArg.languageDto,
      }),
    }),
    createLanguage: build.mutation<CreateLanguageApiResponse, CreateLanguageApiArg>({
      query: (queryArg) => ({ url: `/api/v1/language/create`, method: 'POST', params: { code: queryArg.code } }),
    }),
    getLanguageDetails: build.query<GetLanguageDetailsApiResponse, GetLanguageDetailsApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/language/details/${queryArg.languageId}`,
        params: { language: queryArg.language },
      }),
    }),
    getAllLanguages: build.query<GetAllLanguagesApiResponse, GetAllLanguagesApiArg>({
      query: () => ({ url: `/api/v1/language/all` }),
    }),
    deleteLanguage: build.mutation<DeleteLanguageApiResponse, DeleteLanguageApiArg>({
      query: (queryArg) => ({ url: `/api/v1/language/${queryArg.languageId}/delete`, method: 'DELETE' }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type UpdateLanguageApiResponse = /** status 200 OK */ object;
export type UpdateLanguageApiArg = {
  languageId: number;
  languageDto: LanguageDto;
};
export type CreateLanguageApiResponse = /** status 200 OK */ object;
export type CreateLanguageApiArg = {
  code: string;
};
export type GetLanguageDetailsApiResponse = /** status 200 OK */ ObjectRevisionDto[];
export type GetLanguageDetailsApiArg = {
  languageId: number;
  language?: string;
};
export type GetAllLanguagesApiResponse = /** status 200 OK */ LanguageDto[];
export type GetAllLanguagesApiArg = void;
export type DeleteLanguageApiResponse = /** status 200 OK */ object;
export type DeleteLanguageApiArg = {
  languageId: number;
};
export type LanguageDto = {
  id?: number;
  code: string;
};
export type UserDto = {
  id?: number;
  firstName?: string;
  lastName?: string;
  username?: string;
  clinicIds?: number[];
  specialityIds?: number[];
  phoneNumber?: string;
  emailAddress?: string;
  address?: string;
  profilePicture?: string;
  type?: 'ADMIN' | 'MANAGER' | 'REGISTRATOR' | 'SPECIALIST' | 'ASSISTANT';
};
export type ObjectRevisionDto = {
  object?: object;
  revisionType?: string;
  revisionDate?: number;
  revisionId?: number;
  user?: UserDto;
};
export const {
  useUpdateLanguageMutation,
  useCreateLanguageMutation,
  useGetLanguageDetailsQuery,
  useGetAllLanguagesQuery,
  useDeleteLanguageMutation,
} = injectedRtkApi;
