import React from 'react';
import { RegionGrid } from '../../../components/RegionGrid/RegionGrid';

export default function Region() {
  return (
    <div className={'content-block'}>
      <RegionGrid />
    </div>
  );
}
