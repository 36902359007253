import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import appInfo from '../appInfo';
import routes from '../appRoutes';
import { HomePage } from '../pages';
import { SideNavOuterToolbar as SideNavBarLayout } from '../layouts';
import { isAuthenticatedSelector } from '../redux/modules/user';
import { useAppSelector } from '../redux/store';

/**
 * Provide the actual content when the user is authenticated.
 * On a high level, this includes the Drawer menu with the respective routes and the footer.
 */
export default function Content(): JSX.Element {
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);

  return (
    <SideNavBarLayout title={appInfo.title}>
      <Routes>
        <Route path={'/home'} element={<HomePage />} />
        {routes.map(({ path, component }) => {
          if (isAuthenticated) {
            return <Route key={path} path={path} Component={component} />;
          } else {
            return <Route key={path} element={<Navigate key={path} to="/" replace />} />;
          }
        })}
        <Route path="*" element={<Navigate to="/home" replace />} />
      </Routes>
      <></>
    </SideNavBarLayout>
  );
}
