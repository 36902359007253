import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Scheduler, { Resource, Scrolling, View } from 'devextreme-react/scheduler';
import { LoadPanel, ScrollView} from 'devextreme-react';
import { WorkingHoursDto, useGetCalendarForSpecialityByPeriodQuery } from '../../api/medapp/calendar.generated';
import { AppointmentComponent } from '../SchedulerComponent/SchedulerRenderers';
import { AppointmentDTO } from '../../api/medapp/generated';
import { cloneDeep, flattenDeep } from 'lodash';
import { calculateAppointmentColor, generateItems, generatePhotoBySize, getTranslatedValue } from '../helpers';
import { useGetAllUserMultiLangQuery } from '../../api/medapp/user.generated';
import Utils from '../SchedulerComponent/utils';
import { DateTime, Interval } from 'luxon';
import Toolbar from 'devextreme/ui/toolbar';
import { useGetAllSpecialityInUseQuery } from '../../api/medapp/speciality.generated';
import { useTranslation } from 'react-i18next';
import dxSelectBox from 'devextreme/ui/select_box';
import dxForm from 'devextreme/ui/form';
import { Appointment } from 'devextreme/ui/scheduler';
import { useCreateAppointmentMutation, useEditAppointmentMutation } from '../../api/medapp/appointment.generated';
import Holidays from 'date-holidays';
import { useGetAllClinicsMultilangQuery } from '../../api/medapp/clinic.generated';
import { ClinicMultiLang } from '../../api';

type CreateAppointmentDTO = Pick<
  AppointmentDTO,
  'appointmentType' | 'status' | 'userId' | 'patientName' | 'phoneNumber' | 'startAt' | 'endAt'
>;

const commonResourceCellStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  height: '120px',
};

const hd = new Holidays();

export const SchedulerBySpeciality = (): JSX.Element => {
  const schedulerRef = useRef<Scheduler>(null);

  const { t, i18n } = useTranslation();
  const language = useMemo(() => i18n.language, [i18n.language]);

  const appointmentTypesTranslations = t('appointmentTypes', { returnObjects: true });
  const appointmentStatusesTranslations = t('appointmentStatuses', { returnObjects: true });

  const [currentViewStartDate, setCurrentViewStartDate] = useState('');
  const [currentViewEndDate, setCurrentViewEndDate] = useState('');

  const [specialityId, setSpecialityId] = useState(null);

  const [createAppointment] = useCreateAppointmentMutation();
  const [updateAppointment] = useEditAppointmentMutation();

  const { currentData: allUsers } = useGetAllUserMultiLangQuery({ userType: 'SPECIALIST' });

  const setStartDate = useCallback(() => {
    if (schedulerRef.current) {
      const startDate = schedulerRef.current.instance.getStartViewDate()?.toISOString().split('T')[0];
      if (startDate && startDate !== currentViewStartDate) {
        setCurrentViewStartDate(startDate);
      }
    }
  }, [currentViewStartDate]);

  const setEndDate = useCallback(() => {
    if (schedulerRef.current) {
      const endDate = schedulerRef.current.instance.getEndViewDate()?.toISOString().split('T')[0];
      if (endDate && endDate !== currentViewEndDate) {
        setCurrentViewEndDate(endDate);
      }
    }
  }, [currentViewEndDate]);

  useEffect(() => {
    if (specialityId !== null) {
      setStartDate();
      setEndDate();
    }
  }, [specialityId, setEndDate, setStartDate]);

  const { currentData: calendarData, isFetching: isFetchingCalendar } = useGetCalendarForSpecialityByPeriodQuery(
    {
      specialityId: specialityId ?? 0,
      startDate: currentViewStartDate,
      endDate: currentViewEndDate,
    },
    { skip: specialityId === null, refetchOnMountOrArgChange: true },
  );

  const { currentData: allSpecialities} = useGetAllSpecialityInUseQuery({
    language: language,
  });

  const { currentData: allClinics, isFetching: isFetchingClinics } = useGetAllClinicsMultilangQuery();

  const scheduleClinicCode = useMemo(() => {
    if (calendarData?.schedules?.[0]) {
      const clinicId = calendarData.schedules[0]?.clinicId;
      if (clinicId) {
        const clinicCountryCode = allClinics?.find((clinic: ClinicMultiLang) => clinic.id === clinicId)?.countryCode;
        return clinicCountryCode?.toUpperCase() || '';
      }
    }
    return '';
  }, [allClinics, calendarData?.schedules]);

  hd.init(scheduleClinicCode, { types: ['public'] });

  const scheduleIntervals: Interval[] = useMemo(() => {
    const intervals: Interval[] = [];
    calendarData?.schedules?.forEach((schedule: any) =>
      intervals.push(Interval.fromDateTimes(new Date(schedule.startTime), new Date(schedule.endTime))),
    );
    return intervals;
  }, [calendarData?.schedules]);

  const workingHoursForCurrentView = useMemo(() => {
    const workingHours: WorkingHoursDto[][] = [];
    const viewStartDate = schedulerRef.current?.instance.getStartViewDate();
    const viewEndDate = schedulerRef.current?.instance.getEndViewDate();

    calendarData?.schedules?.forEach((schedule: any) => {
      if (viewStartDate && viewEndDate && schedule.workingHours) {
        workingHours.push(schedule.workingHours);
      }
    });
    return flattenDeep(workingHours);
  }, [calendarData?.schedules]);

  const onAppointmentRender = useCallback((e: any) => {
    const { appointmentData } = e;    
    e.appointmentElement.style.backgroundColor = calculateAppointmentColor(appointmentData) ?? '';
  }, []);

  const appointmentRenderer = useCallback((e: { targetedAppointmentData: AppointmentDTO }) => {
    const { targetedAppointmentData } = e;
    return AppointmentComponent(targetedAppointmentData);
  }, []);

  const daysOff = hd.getHolidays(new Date(currentViewStartDate).getFullYear());

  const calculateDaysOffForCountry = useMemo(() => {
    if (scheduleClinicCode === 'BG') {
      const modifiedDaysOffForBg = daysOff
        .map((day) => {
          const dayDate = new Date(day.date);
          if (dayDate.getDay() === 0) {
            // 0 is sunday => if day is sunday -> make monday off
            const nextWorkingDay = new Date(dayDate);
            nextWorkingDay.setDate(nextWorkingDay.getDate() + 1);
            const dateResult = DateTime.fromJSDate(nextWorkingDay);
            return [{ date: dateResult.toFormat('yyyy-MM-dd HH:mm:ss'), name: day.name }];
          }

          if (dayDate.getDay() === 6) {
            // 6 is saturday => if day is saturday -> make monday off
            const nextWorkingDay = new Date(dayDate);
            nextWorkingDay.setDate(nextWorkingDay.getDate() + 2);
            const dateResult = DateTime.fromJSDate(nextWorkingDay);
            return [{ date: dateResult.toFormat('yyyy-MM-dd HH:mm:ss'), name: day.name }];
          }

          return [day];
        })
        .flat();
      return modifiedDaysOffForBg;
    } else return daysOff;
  }, [daysOff, scheduleClinicCode]);

  const isNationalHoliday = useCallback(
    (date: Date) => {
      for (let i = 0; i < calculateDaysOffForCountry.length; i++) {
        const datee = new Date(calculateDaysOffForCountry[i].date);
        if (datee.toDateString() === date.toDateString()) {
          return true;
        }
      }

      return false;
    },
    [calculateDaysOffForCountry],
  );

  const dataCellTemplate = useCallback(
    (
      itemData: {
        scheduleId: number;
        groups: any;
        startDate: string | number | Date;
      },
      itemIndex: number,
      itemElement: HTMLElement,
    ) => {
      let isTimeSlotEnabled = false;
      let isHoliday = false;
      const rowUserWorkingHours = calendarData?.schedules?.find(
        (schedule: any) => schedule.userId === itemData.groups.userId,
      )?.workingHours;

      scheduleIntervals.forEach((interval: Interval) => {
        if (rowUserWorkingHours && rowUserWorkingHours.length > 0) {
          const startDate = new Date(itemData.startDate);
          const isEnabledTime = Utils.isEnabledTime(startDate, rowUserWorkingHours, [interval]);
          isHoliday = isNationalHoliday(startDate);
          if (isEnabledTime) {
            isTimeSlotEnabled = true;
          }
        }
      });

      if (!isTimeSlotEnabled) {
        itemElement.classList.add('disable-date');
      }
      if (isHoliday) {
        itemElement.classList.add('date-is-national-holiday');
      }
    },
    [calendarData?.schedules, isNationalHoliday, scheduleIntervals],
  );

const resourceCellRender = (resource: any) => {
  const user = allUsers?.find((user) => user.id === resource.data.userId);
  const fnMultiLang = getTranslatedValue(user?.firstName ?? [], language);
  const lnMultiLang = getTranslatedValue(user?.lastName ?? [], language);
  const fullName = `${fnMultiLang} ${lnMultiLang}`;

  const userImage = user?.profilePicture ? generatePhotoBySize('small', user.profilePicture) : null;
  if (userImage){
 return (
   <div style={{ ...commonResourceCellStyle }}>
     <img src={userImage} alt={fullName} style={{ width: '60%', borderRadius: '10%', margin: '5px' }} />
     <span style={{ textAlign: 'center' }}>{fullName}</span>
   </div>
 );
  } else return <span style={{ ...commonResourceCellStyle }}>{fullName}</span>;
};

  const calculateSpec = useCallback((e: any) => {
    if (e) {
      return e.name[0].value + ' - ' + e.userCount;
    }
    return '';
  }, []);

  const onContentReady = useCallback(
    (e: any) => {
      setStartDate();
      setEndDate();
      const toolbarInstance = Toolbar.getInstance(e.element.querySelector('.dx-toolbar'));
      const items = toolbarInstance.option('items') as unknown[];
      if (items.length === 2) {
        toolbarInstance.option('items', [
          items[0],
          {
            widget: 'dxButton',
            location: 'before',
            options: {
              text: t('buttons.todayButton') || '',
              onClick: () => {
                const currentDate = new Date();
                schedulerRef.current?.instance.option('currentDate', currentDate);
              },
            },
          },
          {
            widget: 'dxSelectBox',
            location: 'before',
            options: {
              visible: true,
              width: '200px',
              displayExpr: calculateSpec,
              valueExpr: 'id',
              value: specialityId,
              onValueChanged: (e: any) => {
                setSpecialityId(e.value);
              },
              items: allSpecialities,
              searchEnabled: true,
              dropDownOptions: { width: '200px' },
            },
          },
          items[1],
        ]);
      }
    },
    [allSpecialities, calculateSpec, setEndDate, setStartDate, specialityId, t],
  );

  const onAppointmentFormOpening = useCallback(
    (e: { form: dxForm; appointmentData?: Appointment; cancel?: boolean }) => {
      const { form } = e;
      const appointmentStatuses = generateItems(appointmentStatusesTranslations).filter(
        (status) => status.value === 'SCHEDULED' || status.value === 'COMPLETED' || status.value === 'CANCELLED',
      );
      const appointmentTypes = generateItems(appointmentTypesTranslations).filter(
        (type) =>
          type.value === AppointmentDTO.appointmentType.INITIAL ||
          type.value === AppointmentDTO.appointmentType.SECONDARY ||
          type.value === AppointmentDTO.appointmentType.ABSENCE ||
          type.value === AppointmentDTO.appointmentType.OTHER,
      );

      const customItems = [
        {
          dataField: 'patientName',
          colSpan: 2,
          editorType: 'dxTextBox',
          label: { text: `${t('appointmentForm.patientName')}` },
          isRequired: true,
          visible: e.appointmentData?.appointmentType !== AppointmentDTO.appointmentType.ABSENCE,
        },
        {
          dataField: 'phoneNumber',
          colSpan: 2,
          editorType: 'dxTextBox',
          label: { text: `${t('appointmentForm.phone')}` },
          isRequired: true,
          visible: e.appointmentData?.appointmentType !== AppointmentDTO.appointmentType.ABSENCE,
        },
        {
          dataField: 'appointmentType',
          editorType: 'dxSelectBox',
          colSpan: 2,
          label: { text: `${t('appointmentType')}` },
          editorOptions: {
            items: appointmentTypes,
            valueExpr: 'value',
            displayExpr: 'displayValue',
            onInitialized: (e: { component: dxSelectBox }) => {
              const { component } = e;
              const value = component.option('value');
              if (value === null) {
                e.component.option('value', appointmentTypes[0].value);
                form.updateData({ appointmentType: appointmentTypes[0].value });
              }
            },
            onValueChanged: (e: { value: any }) => {
              const { value } = e;
              const isVisibleEndAt =
                value === AppointmentDTO.appointmentType.OTHER || value === AppointmentDTO.appointmentType.ABSENCE;
              const isVisiblePatientName = value === AppointmentDTO.appointmentType.ABSENCE ? false : true;
              const isVisiblePhone = value === AppointmentDTO.appointmentType.ABSENCE ? false : true;
              const isVisibleStatus = value === AppointmentDTO.appointmentType.ABSENCE ? false : true;

              const patientNameItemIndex = customItems.findIndex((item) => item.dataField === 'patientName');
              const endAtItemIndex = customItems.findIndex((item) => item.dataField === 'endAt');
              const phoneItemIndex = customItems.findIndex((item) => item.dataField === 'phoneNumber');
              const statusItemIndex = customItems.findIndex((item) => item.dataField === 'status');

              if (endAtItemIndex !== -1) {
                customItems[endAtItemIndex].visible = isVisibleEndAt;
              }
              if (patientNameItemIndex !== -1) {
                customItems[patientNameItemIndex].visible = isVisiblePatientName;
              }
              if (phoneItemIndex !== -1) {
                customItems[phoneItemIndex].visible = isVisiblePhone;
              }
              if (statusItemIndex !== -1) {
                customItems[statusItemIndex].visible = isVisibleStatus;
              }
              form.option('items', customItems);
            },
          },
        },
        {
          dataField: 'status',
          editorType: 'dxSelectBox',
          label: { text: `${t('appointmentStatus')}` },
          colSpan: 2,
          visible: e.appointmentData?.appointmentType !== AppointmentDTO.appointmentType.ABSENCE,
          editorOptions: {
            items: appointmentStatuses,
            valueExpr: 'value',
            displayExpr: 'displayValue',
            onInitialized: (e: { component: dxSelectBox }) => {
              const { component } = e;
              const value = component.option('value');
              if (value === null) {
                e.component.option('value', appointmentStatuses[0].value);
                form.updateData({ status: appointmentStatuses[0].value });
              }
            },
          },
        },
        {
          dataField: 'startAt',
          colSpan: 2,
          editorType: 'dxDateBox',
          editorOptions: { type: 'datetime' },
          label: { text: `${t('appointmentForm.startAt')}` },
        },
        {
          dataField: 'endAt',
          colSpan: 2,
          editorType: 'dxDateBox',
          editorOptions: { type: 'datetime' },
          label: { text: `${t('appointmentForm.endAt')}` },
          visible:
            e.appointmentData?.appointmentType === AppointmentDTO.appointmentType.ABSENCE ||
            e.appointmentData?.appointmentType === AppointmentDTO.appointmentType.OTHER,
        },
      ];

      form.option('items', customItems);
    },
    [appointmentStatusesTranslations, appointmentTypesTranslations, t],
  );

  const onCreate = useCallback(
    (e: { appointmentData: Appointment }) => {
      const { appointmentData } = e;

      let newAppointment: CreateAppointmentDTO = {
        userId: appointmentData.userId ?? 0,
        patientName: appointmentData.patientName,
        phoneNumber: appointmentData.phoneNumber,
        status: appointmentData.status,
        appointmentType: appointmentData.appointmentType,
        startAt: appointmentData.startAt,
      };
      if (appointmentData.status === 'OTHER' || 'ABSENCE') {
        newAppointment.endAt = appointmentData.endAt;
      }
      createAppointment({
        appointmentDto: newAppointment,
      });
    },
    [createAppointment],
  );

  const onEdit = useCallback(
    (e: { newData: Appointment }) => {
      const { newData } = e;
      updateAppointment({ appointmentId: newData.id, appointmentDto: newData as AppointmentDTO });
    },
    [updateAppointment],
  );

  const calculateStartHour = useCallback(() => {
    const schedulerWeekday = schedulerRef?.current?.instance
      .getEndViewDate()
      .toLocaleString('en', { weekday: 'long' })
      .toUpperCase();
    const startHours = workingHoursForCurrentView
      ?.filter((wh: any) => wh.dayOfWeek === schedulerWeekday)
      .map((wh: any) => wh.startTime);

    if (startHours && startHours.length > 0) {
      const earliestHour = Math.min(...startHours.map((time: any) => new Date(time).getHours()));
      return earliestHour;
    }
    return 6;
  }, [workingHoursForCurrentView]);

  const calculateEndHour = useCallback(() => {
    const schedulerWeekday = schedulerRef?.current?.instance
      .getEndViewDate()
      .toLocaleString('en', { weekday: 'long' })
      .toUpperCase();

    const endHours = workingHoursForCurrentView
      ?.filter((wh: any) => wh.dayOfWeek === schedulerWeekday)
      .map((wh: any) => wh.endTime);

    if (endHours && endHours.length > 0) {
      const latestHour = Math.max(...endHours.map((time: any) => new Date(time).getHours()));
      //add +1 hour in order not to cut last appointments
      return latestHour + 1;
    }
    return 20;
  }, [workingHoursForCurrentView]);

 return (
   <>
     <LoadPanel
       visible={isFetchingCalendar || isFetchingClinics}
       position={{ my: 'center', at: 'center', of: `#schedulerBySpeciality` }}
       shading={true}
       shadingColor={'rgba(0,0,0,.32)'}
       height="100%"
     />
     {allSpecialities && (
       <ScrollView height="100%" width="100%">
         <Scheduler
           id="schedulerBySpeciality"
           ref={schedulerRef}
           height={'auto'}
           dataSource={cloneDeep(calendarData?.appointments) ?? []}
           cellDuration={10}
           defaultCurrentView="timelineDay"
           groups={['userId']}
           maxAppointmentsPerCell={1}
           recurrenceRuleExpr="recurrence"
           startDayHour={calculateStartHour()}
           endDayHour={calculateEndHour()}
           startDateExpr="startAt"
           endDateExpr="endAt"
           appointmentRender={appointmentRenderer}
           onAppointmentRendered={onAppointmentRender}
           dataCellTemplate={dataCellTemplate}
           onContentReady={onContentReady}
           onAppointmentFormOpening={onAppointmentFormOpening}
           onAppointmentAdding={onCreate}
           onAppointmentUpdating={onEdit}
           appointmentDragging={{
             onDragStart(e) {
               e.cancel = true;
             },
           }}
           resourceCellRender={resourceCellRender}
         >
           <View type="timelineDay" groupOrientation="vertical" />
           <Resource dataSource={calendarData?.schedules ?? []} fieldExpr="userId" valueExpr="userId" />
           <Scrolling mode="virtual" />
         </Scheduler>
       </ScrollView>
     )}
   </>
 );
};
