import { useGetAllUserMultiLangQuery, useDeleteUserMutation } from '../../api/medapp/user.generated';
import { useCallback, useRef } from 'react';
import { DataGrid, LoadPanel } from 'devextreme-react';
import {
  Column,
  Editing,
  Lookup,
  Toolbar,
  Item,
  FilterRow,
  LoadPanel as GridLoadPanel,
  Selection,
} from 'devextreme-react/data-grid';
import infoDialog from '../dialogs/InfoDialog';
import { cloneDeep } from 'lodash';
import { UserMultilang } from '../../api';
import { User } from '../../api/medapp/generated';
import { useDispatch } from 'react-redux';
import { useGetClinicsForCurrentUserQuery } from '../../api/medapp/clinic.generated';
import { getTranslatedValue, renderProfileImage } from '../helpers';
import { CreateUserDialog } from './CreateUserDialog';
import { setUserToEdit, toggleCreateUserDialog } from '../../redux/modules/user';
import { useLanguage } from '../hooks/useLanguageHook';
import { useTranslation } from 'react-i18next';
import { EditUserDialog } from './EditUserDialog';

export const UserDataGrid = () => {
  const { t } = useTranslation();

  const gridRef = useRef<DataGrid>(null);
  const dispatch = useDispatch();
  const { language } = useLanguage();

  const { currentData: allUsers, isFetching: isFetchingUsers } = useGetAllUserMultiLangQuery(
    { userType: undefined },
    { refetchOnMountOrArgChange: true },
  );

  const { currentData: allClinicsForCurrentUser, isFetching: isFetchingClinicsForCurrentUser } =
    useGetClinicsForCurrentUserQuery({ lang: language });

  const [deleteUser] = useDeleteUserMutation();

  const calculateNameCellValue = useCallback(
    (data: UserMultilang) => {
      const firstName = getTranslatedValue(data.firstName ?? [], language);
      const lastName = getTranslatedValue(data.lastName ?? [], language);
      return firstName + ' ' + lastName;
    },
    [language],
  );

  const calculateSpecialityTranslation = useCallback(
    (data: UserMultilang) => {
      switch (data.type) {
        case User.type.SPECIALIST:
          return t('userTypes.SPECIALIST');
        case User.type.MANAGER:
          return t('userTypes.MANAGER');
        case User.type.REGISTRATOR:
          return t('userTypes.REGISTRATOR');
        case User.type.ASSISTANT:
          return t('userTypes.ASSISTANT');
      }
    },
    [t],
  );

  const onCreate = useCallback(() => {
    dispatch(toggleCreateUserDialog());
  }, [dispatch]);

  const onEdit = useCallback(() => {
    const selectedItems = gridRef.current?.instance.getSelectedRowsData();
    if (selectedItems === undefined || selectedItems.length === 0) {
      infoDialog({ message: `${t('userDataGrid.NoEntryIsSelectedException')}` });
      return;
    }
    if (selectedItems.length > 1) {
      infoDialog({ message: `${t('userDataGrid.EdditingMultipleUsersException')}` });
      return;
    }
    if (selectedItems.length === 1) {
      dispatch(setUserToEdit(selectedItems[0]));
    }
  }, [dispatch, t]);

  const onDelete = useCallback(
    (data: UserMultilang) => {
      if (data.id) {
        deleteUser({
          userId: data.id,
        });
      }
    },
    [deleteUser],
  );

  const imgCellTemplate = useCallback(
    (container: any, options: any) => {
      renderProfileImage(container, options.data.profilePicture || null, options.data, language);
    },
    [language],
  );

  return (
    <>
      <CreateUserDialog />
      <EditUserDialog />
      <LoadPanel
        visible={isFetchingUsers || isFetchingClinicsForCurrentUser}
        position={{ my: 'center', at: 'center', of: `#userDataGrid` }}
        shading={true}
        shadingColor={'rgba(0,0,0,.32)'}
        height="100%"
      />
      <DataGrid
        ref={gridRef}
        id={'userDataGrid'}
        height={'100%'}
        width={'100%'}
        columnAutoWidth={true}
        dataSource={cloneDeep(allUsers)}
        onRowRemoving={(e) => onDelete(e.data)}
      >
        <Selection mode={'multiple'} />
        <GridLoadPanel enabled={false} />
        <Toolbar>
          <Item
            widget={'dxButton'}
            visible={true}
            options={{
              icon: 'plus',
              onClick: () => onCreate(),
            }}
            location={'after'}
          />
          <Item
            widget={'dxButton'}
            visible={true}
            options={{
              icon: 'edit',
              onClick: onEdit,
            }}
            location={'after'}
          />
        </Toolbar>
        <FilterRow visible={true} />
        <Editing allowDeleting={true} />
        <Column
          dataField={'profilePicture'}
          caption={''}
          cellTemplate={imgCellTemplate}
          width={'80px'}
          allowSorting={false}
          allowFiltering={false}
        />
        <Column
          dataField={'firstName'}
          caption={`${t('userDataGrid.name')}`}
          calculateCellValue={calculateNameCellValue}
          allowEditing={false}
        />
        <Column dataField={'emailAddress'} caption={`${t('userDataGrid.email')}`} />
        <Column dataField={'phoneNumber'} caption={`${t('userDataGrid.phone')}`} />
        <Column
          dataField={'type'}
          caption={`${t('userDataGrid.role')}`}
          calculateCellValue={calculateSpecialityTranslation}
          allowEditing={false}
        />
        <Column dataField={'username'} caption={`${t('userDataGrid.username')}`} allowEditing={false} />
        {/* Consider using TagBox here if there are use-cases for users with many clinics */}
        <Column dataField={'clinicIds'} caption={`${t('userDataGrid.clinics')}`} allowEditing={false}>
          <Lookup dataSource={allClinicsForCurrentUser} valueExpr={'id'} displayExpr="name" />
        </Column>
      </DataGrid>
    </>
  );
};
