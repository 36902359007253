import { useCallback, useMemo } from 'react';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { DataGrid, LoadPanel } from 'devextreme-react';
import {
  Column,
  Editing,
  FilterRow,
  HeaderFilter,
  Lookup,
  MasterDetail,
  LoadPanel as LoadPanelProp,
} from 'devextreme-react/data-grid';
import { LanguageDetailGrid } from '../LanguageDetailGrid/LanguageDetailGrid';
import { updateObjectAttribute } from '../helpers';
import { useGetAllRegionsMultiLangQuery } from '../../api/medapp/region.generated';
import { useGetAllCountryMultiLangQuery } from '../../api/medapp/country.generated';
import {
  useCreateCityMutation,
  useUpdateCityMutation,
  useGetAllCityMultiLangQuery,
} from '../../api/medapp/city.generated';
import { CityMultiLang, RegionMultiLang, CountryMultiLang, LocalizedString } from '../../api';

export const CityGrid = () => {
  const { data: allCities, isLoading: isFetchingCities } = useGetAllCityMultiLangQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const { data: allRegions, isLoading: isFetchingRegions } = useGetAllRegionsMultiLangQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const { data: allCountries, isLoading: isFetchingCountries } = useGetAllCountryMultiLangQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const [createCity, { isLoading: isCreatingCity }] = useCreateCityMutation();
  const [updateCity, { isLoading: isUpdatingCity }] = useUpdateCityMutation();

  const { i18n } = useTranslation();
  const language = useMemo(() => i18n.language, [i18n.language]);

  const displayRegion = useCallback(
    (data: RegionMultiLang) => {
      const regionName = data.name?.find((name: any) => name.language === `${language}`)?.value;
      return regionName;
    },
    [language],
  );

  const displayCountry = useCallback(
    (data: CountryMultiLang) => {
      const countryNameMultiLang = allCountries?.find((country) => country.id === data.id)?.name;
      const countryName = countryNameMultiLang?.find((name: any) => name.language === `${language}`)?.value;
      return countryName;
    },
    [allCountries, language],
  );

  const create = (data: CityMultiLang) => {
    createCity({
      cityMultiLangDto: data,
      language: 'en',
    });
  };

  const updateCityAttribute = (oldData: CityMultiLang, newData: unknown) => {
    if (oldData.id) {
      updateCity({
        cityId: oldData!.id,
        cityMultiLangDto: updateObjectAttribute(oldData, newData),
      });
    }
  };

  const updateCityLanguage = useCallback(
    (cityDto: CityMultiLang, oldData: LocalizedString, newData: { value: string }) => {
      const updatedCity = cloneDeep(cityDto);
      const name = updatedCity.name?.find((languages: LocalizedString) => languages.language === oldData.language);
      if (name && cityDto.id) {
        name.value = newData.value;

        updateCity({
          cityId: cityDto!.id,
          cityMultiLangDto: updateObjectAttribute(oldData, newData),
        });
      }
    },
    [updateCity],
  );

  const detailView = useCallback(
    (city: CityMultiLang, names: LocalizedString[]) => (
      <LanguageDetailGrid
        names={names}
        onRowUpdating={(oldData, newData) => {
          updateCityLanguage(city, oldData, newData);
        }}
      />
    ),
    [updateCityLanguage],
  );

  return (
    <>
      <LoadPanel
        visible={isFetchingCities || isCreatingCity || isUpdatingCity || isFetchingRegions || isFetchingCountries}
        position={{ my: 'center', at: 'center', of: `#cityDataGrid` }}
        shading={true}
        shadingColor={'rgba(0,0,0,.32)'}
        height="100%"
      />
      <DataGrid
        id={'cityDataGrid'}
        // height={'100%'}
        // width={'100%'}
        columnAutoWidth={true}
        dataSource={cloneDeep(allCities)}
        onRowInserting={({ data }) => create(data)}
        onRowUpdating={({ oldData, newData }) => {
          updateCityAttribute(oldData, newData);
        }}
      >
        <LoadPanelProp enabled={false} />
        <Editing allowAdding={true} allowUpdating={true} />
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        <Column dataField={'id'} allowEditing={false} />
        <Column dataField={'code'} />
        <Column dataField={'regionId'} caption={'Region'}>
          <Lookup dataSource={allRegions} valueExpr={'regionId'} displayExpr={displayRegion} />
        </Column>
        <Column dataField={'countryId'} caption={'Country'}>
          <Lookup dataSource={allCountries} valueExpr={'id'} displayExpr={displayCountry} />
        </Column>
        <MasterDetail enabled={true} component={({ data }) => detailView(data.data, data.data.name)} />
      </DataGrid>
    </>
  );
};
