import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import './user-panel.scss';
import Menu from 'devextreme-react/menu';
import { logout, isAuthenticatedSelector } from './../../redux/modules/user';
import { useKeycloak } from '@react-keycloak/web';
import { useAppSelector } from '../../redux/store';
import { userProfilePicture } from '../../redux/modules/user';
import { generatePhotoBySize } from '../helpers';

function UserPanel(): JSX.Element {
  const dispatch = useDispatch();
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);
  const { keycloak } = useKeycloak();

  const onLogoutClick = () => {
    dispatch(logout(keycloak));
  };

  const onLoginClick = () => {
    keycloak.login({ redirectUri: window.location.origin + '/home' });
  };

  const userPicture = useAppSelector(userProfilePicture);

  const calculateUserPhoto = useCallback(() => {
    if (userPicture) {
      return generatePhotoBySize('small', userPicture);
    } else {
      return 'user';
    }
  }, [userPicture]);

  return (
    <>
      {isAuthenticated === true ? (
        <Menu
          dataSource={[
            {
              text: '',
              icon: calculateUserPhoto(),
              items: [
                {
                  text: 'Logout',
                  icon: 'runner',
                },
              ],
            },
          ]}
          onItemClick={(e) => {
            if (e.itemData?.text === 'Logout') onLogoutClick();
          }}
        />
      ) : (
        <Menu
          dataSource={[
            {
              text: '',
              icon: 'user',
              items: [
                {
                  text: 'Login',
                  icon: 'arrowright',
                },
              ],
            },
          ]}
          onItemClick={(e) => {
            if (e.itemData?.text === 'Login') onLoginClick();
          }}
        />
      )}
    </>
  );
}

export default UserPanel;
