import { SchedulerBySpeciality } from '../../components/SchedulerBySpeciality/SchedulerBySpeciality';

export default function SpecialitySchedule() {
  return (
    <div className={'content-block'}>
      <div />
      <SchedulerBySpeciality />
    </div>
  );
}
