import { DataGrid } from 'devextreme-react';
import { Column, Editing } from 'devextreme-react/data-grid';
import { useTranslation } from 'react-i18next';

export interface UserNameDto {
  language: string;
  firstName: string;
  lastName: string;
}

export interface LanguageDetailGridProps {
  dataSource: UserNameDto[];
  onRowUpdated: (data: UserNameDto) => void;
}

export const UserNameDetailGrid = (props: LanguageDetailGridProps) => {
  const { t } = useTranslation();

  return (
    <DataGrid
      dataSource={props.dataSource}
      onRowUpdated={(e) => {
        const { data } = e;
        props.onRowUpdated(data);
      }}
    >
      <Editing allowUpdating={true} />
      <Column dataField={'language'} caption={`${t('userNameDetailGrid.language')}`} allowEditing={false} />
      <Column dataField={'firstName'} caption={`${t('userNameDetailGrid.firstName')}`} />
      <Column dataField={'lastName'} caption={`${t('userNameDetailGrid.lastName')}`} />
    </DataGrid>
  );
};
