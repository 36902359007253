import { DataGrid, LoadPanel } from 'devextreme-react';
import {
  LocalizedStringDto,
  useCreateCountryMutation,
  useGetAllCountryMultiLangQuery,
  useUpdateCountryMutation,
} from '../../api/medapp/country.generated';
import { cloneDeep } from 'lodash';
import {
  Column,
  Editing,
  FilterRow,
  HeaderFilter,
  MasterDetail,
  LoadPanel as LoadPanelProp,
} from 'devextreme-react/data-grid';
import { LanguageDetailGrid } from '../LanguageDetailGrid/LanguageDetailGrid';
import { useCallback } from 'react';
import { updateObjectAttribute } from '../helpers';
import { CountryMultiLang } from '../../api';

export const CountryGrid = () => {
  const { currentData: allCountries, isFetching: isFetchingCountries } = useGetAllCountryMultiLangQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const [createCountry, { isLoading: isCreatingCountry }] = useCreateCountryMutation();
  const [updateCountry, { isLoading: isUpdatingCountry }] = useUpdateCountryMutation();

  const create = (data: any) => {
    createCountry({
      countryMultiLangDto: {
        code: data.code,
        name: [],
      },
    });
  };

  const updateCountryAttribute = (oldData: CountryMultiLang, newData: unknown) => {
    if (oldData.id) {
      updateCountry({
        countryId: oldData!.id,
        countryMultiLangDto: updateObjectAttribute(oldData, newData),
      });
    }
  };

  const updateCountryLanguage = useCallback(
    (countryDto: CountryMultiLang, oldData: LocalizedStringDto, newData: { value: string }) => {
      const updatedCountry = cloneDeep(countryDto);
      const name = updatedCountry.name?.find(
        (languages: LocalizedStringDto) => languages.language === oldData.language,
      );
      if (name && countryDto.id) {
        name.value = newData.value;

        updateCountry({
          countryId: countryDto!.id,
          countryMultiLangDto: updatedCountry,
        });
      }
    },
    [updateCountry],
  );

  const detailView = useCallback(
    (country: CountryMultiLang, names: LocalizedStringDto[]) => (
      <LanguageDetailGrid
        names={names}
        onRowUpdating={(oldData, newData) => {
          updateCountryLanguage(country, oldData, newData);
        }}
      />
    ),
    [updateCountryLanguage],
  );

  return (
    <>
      <LoadPanel
        visible={isFetchingCountries || isCreatingCountry || isUpdatingCountry}
        position={{ my: 'center', at: 'center', of: `#countryDataGrid` }}
        shading={true}
        shadingColor={'rgba(0,0,0,.32)'}
        height="100%"
      />
      <DataGrid
        id={'countryDataGrid'}
        height={'100%'}
        width={'100%'}
        columnAutoWidth={true}
        dataSource={cloneDeep(allCountries)}
        onRowInserting={({ data }) => create(data)}
        onRowUpdating={({ oldData, newData }) => {
          updateCountryAttribute(oldData, newData);
        }}
      >
        <LoadPanelProp enabled={false} />
        <Editing allowAdding={true} allowUpdating={true} />
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        <Column dataField={'id'} allowEditing={false} />
        <Column dataField={'code'} />
        <MasterDetail enabled={true} component={({ data }) => detailView(data.data, data.data.name)} />
      </DataGrid>
    </>
  );
};
