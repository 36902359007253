import { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { useAppSelector } from '../../redux/store';
import { addressToEditSelector, setAddressToEdit } from '../../redux/modules/address';
import { Popup, SelectBox } from 'devextreme-react';
import Form, { SimpleItem, Tab, TabPanelOptions, TabbedItem } from 'devextreme-react/form';
import { useUpdateAddressMutation } from '../../api/medapp/address.generated';
import { ToolbarItem } from 'devextreme-react/popup';
import { LanguageDetailGrid } from '../LanguageDetailGrid/LanguageDetailGrid';
import { getTranslatedValue, updateObjectLanguage } from '../helpers';
import { selectAllCities } from '../../redux/modules/city';
import { useLanguage } from '../hooks/useLanguageHook';

const defaultEditorOptions = { stylingMode: 'outlined' };

export const EditAddressDialog = () => {
  const formRef = useRef<Form>(null);
  const dispatch = useDispatch();
  const addressToEdit = cloneDeep(useAppSelector(addressToEditSelector));

  const cancelEditing = useCallback(() => {
    dispatch(setAddressToEdit(undefined));
  }, [dispatch]);

  const updateAddressTranslations = useCallback(
    (oldData: any, newData: any) => {
      updateObjectLanguage(addressToEdit, oldData.language, newData.value);
    },
    [addressToEdit],
  );

  const [updateAddress] = useUpdateAddressMutation();

  const finish = useCallback(() => {
    const validationResult = formRef.current?.instance.validate();
    if (validationResult?.isValid && addressToEdit?.id && addressToEdit) {
      updateAddress({
        addressId: addressToEdit.id,
        addressMultiLangDto: addressToEdit,
      });
      dispatch(setAddressToEdit(undefined));
    }
  }, [dispatch, addressToEdit, updateAddress]);

  const { language } = useLanguage();

  const allCities = useAppSelector(selectAllCities);

  const CityIdRenderer = useCallback(() => {
    return (
      <SelectBox
        stylingMode="outlined"
        dataSource={allCities}
        valueExpr={'id'}
        value={addressToEdit?.cityId}
        displayExpr={(data) => getTranslatedValue(data, language)}
        onValueChanged={({ value }) => {
          addressToEdit && value && dispatch(setAddressToEdit({ ...addressToEdit, cityId: value }));
        }}
      />
    );
  }, [addressToEdit, allCities, dispatch, language]);

  const LanguageDetailGridRenderer = useCallback(() => {
    return (
      <LanguageDetailGrid
        names={addressToEdit?.name ?? []}
        onRowUpdating={(oldData, newData) => {
          updateAddressTranslations(oldData, newData);
        }}
      />
    );
  }, [addressToEdit?.name, updateAddressTranslations]);

  return (
    <div id={'EditAddressDialogContainer'}>
      <Popup visible={addressToEdit !== undefined} resizeEnabled={true} showCloseButton={false}>
        <Form id="EditAddressForm" ref={formRef} formData={addressToEdit} scrollingEnabled={true}>
          <TabbedItem>
            <TabPanelOptions deferRendering={false} />
            <Tab title={'INFO'} colCount={2}>
              <SimpleItem dataField={'apartmentNumber'} editorOptions={defaultEditorOptions} />
              <SimpleItem dataField={'floorNumber'} editorOptions={defaultEditorOptions} />
              <SimpleItem dataField={'latitude'} editorOptions={defaultEditorOptions} />
              <SimpleItem dataField={'longitude'} editorOptions={defaultEditorOptions} />
              <SimpleItem dataField={'postalCode'} editorOptions={defaultEditorOptions} />
              <SimpleItem dataField={'streetNumber'} editorType="dxNumberBox" editorOptions={defaultEditorOptions} />
              <SimpleItem dataField={'cityId'} render={CityIdRenderer} />
            </Tab>
            <Tab title={'TRANSLATIONS'}>
              <SimpleItem render={LanguageDetailGridRenderer} />
            </Tab>
          </TabbedItem>
        </Form>
        <ToolbarItem
          widget={'dxButton'}
          toolbar={'bottom'}
          location={'after'}
          options={{
            text: 'Confirm',
            elementAttr: { 'aria-label': 'confirm' },
            onClick: finish,
          }}
        />
        <ToolbarItem
          widget={'dxButton'}
          toolbar={'bottom'}
          location={'after'}
          options={{
            text: 'Cancel',
            elementAttr: { 'aria-label': 'cancel' },
            onClick: cancelEditing,
          }}
        />
      </Popup>
    </div>
  );
};
