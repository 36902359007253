import { cloneDeep } from 'lodash';
import { LocalizedStringDto } from '../api/medapp/country.generated';
import { Appointment, LocalizedStringDTO } from '../api/medapp/generated';
import { GlobalSettings } from '../globalSettings';

export const updateObjectAttribute = (oldData: any, newData: any) => {
  const updatedObject = cloneDeep(oldData);
  Object.entries(newData).forEach(([key, value]) => {
    updatedObject[`${key}`] = value;
  });

  return updatedObject;
};

export const updateObjectLanguage = (object: any, targetLanguage: string, newValue: string): any => {
  const updatedObject = cloneDeep(object);
  const name = updatedObject.name?.find((languages: LocalizedStringDto) => languages.language === targetLanguage);
  if (name) {
    name.value = newValue;
  }
  return updatedObject;
};

export const getTranslatedValue = (value: LocalizedStringDTO[], language: string) => {
  if (!Array.isArray(value)) return '';
  return value?.find((val) => val.language === language)?.value ?? '';
};

export const calculateAppointmentColor = (appointmentData: any) => {
  const defaultColor = '#4682B4'; //blue

  if (appointmentData?.status === Appointment.status.COMPLETED) {
    return 'rgb(192,192,192)'; //grey
  } else if (appointmentData?.appointmentType === Appointment.type.SECONDARY) {
    return 'rgb(154,205,50)'; //green
  } else if (
    appointmentData?.appointmentType === Appointment.type.OPEN ||
    appointmentData?.appointmentType === Appointment.type.INSURANCE ||
    appointmentData?.appointmentType === Appointment.type.OTHER
  ) {
    return 'rgb(205,92,92)'; //red
  } else if (appointmentData?.appointmentType === Appointment.type.ABSENCE) {
    return 'rgb(246,246,30)'; //yellow
  } else return defaultColor;
};

//generate items for dropdown
export const generateItems = (data: any) => {
  const items = Object.keys(data).map((key) => ({
    value: key,
    displayValue: data[key],
  }));
  return items;
};

export const generatePhotoBySize = (size: string, url: string) => {
  if (url !== undefined) {
    const photoUrl = `${GlobalSettings.getInstance().getMinioUrl()}${size}_${url}`;
    return photoUrl;
  } else return '';
};

export const createNameInitials = (data: any, language: any) => {
  const firstNameLetter = getTranslatedValue(data?.firstName ?? [], language).charAt(0);
  const lastNameLetter = getTranslatedValue(data?.lastName ?? [], language).charAt(0);
  const initials = `${firstNameLetter}${lastNameLetter}`;
  return initials;
};

export const renderProfileImage = (
  container: HTMLElement,
  profilePicture: string | null,
  nameData: any,
  language: string,
) => {
  container.innerHTML = '';
  const elementSize = { width: '45px', height: '45px', borderRadius: '50%' };

  if (profilePicture) {
    const imgElement = document.createElement('img');
    imgElement.src = generatePhotoBySize('small', profilePicture);
    imgElement.alt = 'Profile Picture';

    Object.assign(imgElement.style, elementSize, {
      objectFit: 'cover',
    });

    container.style.display = 'flex';
    container.style.alignItems = 'center';
    container.style.justifyContent = 'center';
    container.appendChild(imgElement);
  } else {
    const letterElement = document.createElement('div');
    letterElement.textContent = createNameInitials(nameData, language);

    Object.assign(letterElement.style, elementSize, {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#ccc',
      fontSize: '18px',
      color: '#fff',
      fontWeight: 'bold',
    });

    container.style.display = 'flex';
    container.style.alignItems = 'center';
    container.style.justifyContent = 'center';
    container.appendChild(letterElement);
  }
};
