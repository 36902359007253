import { RootState } from '.';
import { AddressMultiLang } from '../../api';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type AddressState = {
  addressToEdit?: AddressMultiLang;
  showCreateAddressDialog: boolean;
};

const initialState: AddressState = {
  addressToEdit: undefined,
   showCreateAddressDialog: false,
};

const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    setAddressToEdit(state, action: PayloadAction<AddressMultiLang | undefined>) {
      state.addressToEdit = action.payload;
    },
    toggleCreateAddressDialog(state) {
      state.showCreateAddressDialog = !state.showCreateAddressDialog;
    },
  },
});

export const { setAddressToEdit, toggleCreateAddressDialog } = addressSlice.actions;
export default addressSlice.reducer;

export const addressToEditSelector: (state: RootState) => AddressMultiLang | undefined = (state: RootState) =>
  state.address.addressToEdit;

export const createAddressDialogVisibleSelector: (state: RootState) => boolean = (state: RootState) =>
  state.address.showCreateAddressDialog;
