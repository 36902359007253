import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { ClinicMultiLang } from '../../api';

export type ClinicState = {
  clinicToEdit?: ClinicMultiLang;
};

const initialState: ClinicState = {
  clinicToEdit: undefined,
};

const clinicSlice = createSlice({
  name: 'clinic',
  initialState,
  reducers: {
    setClinicToEdit(state, action: PayloadAction<ClinicMultiLang | undefined>) {
      state.clinicToEdit = action.payload;
    },
  },
});

export const { setClinicToEdit } = clinicSlice.actions;
export default clinicSlice.reducer;

export const clinicToEditSelector: (state: RootState) => ClinicMultiLang | undefined = (state: RootState) =>
  state.clinic.clinicToEdit;
