import { enhancedApi } from './country.generated';

export const registerEnhancedCountryEndpoints = () => {
  enhancedApi.enhanceEndpoints({
    endpoints: {
      getAllCountryMultiLang: {
        providesTags: () => ['Country'],
      },
      createCountry: {
        invalidatesTags: ['Country'],
      },
      updateCountry: {
        invalidatesTags: ['Country'],
      },
    },
  });
};
