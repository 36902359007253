import React from 'react';
import { LanguageGrid } from '../../../components/LanguageGrid/LanguageGrid';

export default function Language() {
  return (
    <div className={'content-block'}>
      <LanguageGrid />
    </div>
  );
}
