import {
  Column,
  FilterRow,
  HeaderFilter,
  Toolbar,
  Item,
  Selection,
  LoadPanel as LoadPanelProp,
} from 'devextreme-react/data-grid';
import { DataGrid, LoadPanel } from 'devextreme-react';
import DataSource from 'devextreme/data/data_source';
import { useRef, useMemo, useCallback } from 'react';
import { ClinicMultiLang } from '../../api';
import { useDispatch } from 'react-redux';
import { useGetAllClinicsMultilangQuery } from '../../api/medapp/clinic.generated';
import { useGetAllOrganizationsMultiLangQuery } from '../../api/medapp/organization.generated';
import { useTranslation } from 'react-i18next';
import { EditClinicDialog } from './EditClinicDialog';
import { setClinicToEdit } from '../../redux/modules/clinic';
import infoDialog from '../dialogs/InfoDialog';
import { getTranslatedValue } from '../helpers';

export const ClinicGrid = () => {
  const { i18n } = useTranslation();

  const clinicGridRef = useRef<DataGrid<ClinicMultiLang, string>>(null);

  const dispatch = useDispatch();

  const { currentData: allClinics, isFetching: isFetchingClinics } = useGetAllClinicsMultilangQuery();

  const { currentData: allOrganizations, isFetching: isFetchingOrganizations } = useGetAllOrganizationsMultiLangQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const language = useMemo(() => i18n.language, [i18n.language]);

  const calculateCLiniicNameDisplayExpr = useCallback(
    (data: ClinicMultiLang) => {
      return data.name.find((name: any) => name.language === language)?.value;
    },
    [language],
  );

  const findOrganizationNameById = useCallback(
    (data: ClinicMultiLang) => {
      return allOrganizations
        ?.find((org) => org.id === data.organizationId)
        ?.name.find((name) => name.language === language)?.value;
    },
    [language, allOrganizations],
  );

  const findAddressNameById = useCallback(
    (data: ClinicMultiLang) => {
      return getTranslatedValue(data.name, language);
    },
    [language],
  );

  const onEdit = useCallback(
    (selectedItems: ClinicMultiLang[] | undefined): void => {
      if (selectedItems === undefined || selectedItems.length === 0) {
        infoDialog({ message: 'No entry is selected' });
        return;
      }
      if (selectedItems.length > 1) {
        infoDialog({ message: 'Editing multiple organizations is currently not supported' });
        return;
      }
      if (selectedItems.length === 1) {
        dispatch(setClinicToEdit(selectedItems[0]));
      }
    },
    [dispatch],
  );

  return (
    <>
      <EditClinicDialog />
      <LoadPanel
        visible={isFetchingClinics || isFetchingOrganizations}
        position={{ my: 'center', at: 'center', of: `#clinicDataGrid` }}
        shading={true}
        shadingColor={'rgba(0,0,0,.32)'}
        height="100%"
      />
      <DataGrid
        ref={clinicGridRef}
        id={'clinicDataGrid'}
        height={'100%'}
        width={'100%'}
        columnAutoWidth={true}
        repaintChangesOnly={true}
        dataSource={new DataSource(allClinics ?? [])}
      >
        <LoadPanelProp enabled={false} />
        <Selection mode={'multiple'} />
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        <Toolbar>
          <Item
            widget={'dxButton'}
            visible={true}
            options={{
              icon: 'edit',
              onClick: () => {
                onEdit(clinicGridRef.current?.instance.getSelectedRowsData());
              },
            }}
            location={'after'}
          />
        </Toolbar>
        <Column dataField={'name'} calculateCellValue={calculateCLiniicNameDisplayExpr} />
        <Column dataField={'phoneNumber'} />
        <Column dataField={'organizationId'} caption={'Organization'} calculateCellValue={findOrganizationNameById} />
        <Column dataField={'addressId'} caption={'Address'} calculateCellValue={findAddressNameById} />
        <Column dataField={'logoUrl'} />
        <Column dataField={'coverPhotoUrl'} />
      </DataGrid>
    </>
  );
};
