import { enhancedApi } from './calendar.generated';

export const registerEnhancedCalendarEndpoints = () => {
  enhancedApi.enhanceEndpoints({
    endpoints: {
      getCalendarForUserByPeriod: {
        providesTags: () => ['SchedulerComponentData'],
      },
      getCalendarForSpecialityByPeriod: {
        providesTags: () => ['SchedulerComponentData'],
      },
    },
  });
};
