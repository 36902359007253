import { createSelector } from '@reduxjs/toolkit';
import { enhancedApi } from '../../api/medapp/city.generated';
import { CityMultiLang } from '../../api';

// Calling `someEndpoint.select(someArg)` generates a new selector that will return
// the query result object for a query with those parameters.
// To generate a selector for a specific query argument, call `select(theQueryArg)`.
// In this case, the users query has no params, so we don't pass anything to select()
const selectCityResult = enhancedApi.endpoints.getAllCityMultiLang.select();

export const selectAllCities = createSelector(
  selectCityResult,
  (cityResult) => (cityResult?.data as CityMultiLang[]) ?? [],
);
