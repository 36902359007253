/** @jsxRuntime classic */
/** @jsx h */
import { autocomplete } from '@algolia/autocomplete-js';
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import { InstantSearch } from 'instantsearch.js';

import {
  debouncedSetInstantSearchUiState,
  getInstantSearchUiState,
  getInstantSearchUrl,
  setInstantSearchUiState,
} from './instantsearch';
import { isModifierEvent } from './isModifierEvent';
import { indexName, searchClient } from './searchClient';
import i18n from '../../translation/config';
import { createNameInitials, generatePhotoBySize, getTranslatedValue } from '../helpers';
import { LocalizedStringDTO } from '../../api/medapp/generated';
import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia';

export type AlgoliaItem = {
  objectID: string;
  email: string[];
  username: string;
  clinicIdList: number[];
  specialties: LocalizedStringDTO[];
  firstName: LocalizedStringDTO[];
  lastName: LocalizedStringDTO[];
  phoneNumber: string;
  profilePicture: string;
};

const doctorDescription = (item: AlgoliaItem) => {
  const fn = getTranslatedValue(item.firstName, i18n.language);
  const ln = getTranslatedValue(item.lastName, i18n.language);
  const allSpecialities = item.specialties
    .filter((s) => s.language === i18n.language)
    .map((s) => s.value)
    .join(', ');

  return `${fn + ' ' + ln + ' | ' + allSpecialities}`;
};

function onSelect({ setIsOpen, setQuery, event, query, algoliaItem }: any) {
  // You want to trigger the default browser behavior if the event is modified.
  if (isModifierEvent(event)) {
    return;
  }

  document.dispatchEvent(new CustomEvent('algoliaSearchStateUpdated', { detail: algoliaItem }));
  setQuery(query);
  setIsOpen(false);
  setInstantSearchUiState({
    query,
  });
}

function getItemUrl({ query }: any) {
  return getInstantSearchUrl({
    query,
  });
}

function getItemWrapper({ html, children, query, algoliaItem }: any) {
  const uiState = {
    query,
  };

  let profileElement;
  if (algoliaItem.profilePicture) {
    const photoUrl = generatePhotoBySize('small', algoliaItem.profilePicture);
    profileElement = html`<img
      src="${photoUrl}"
      alt="Profile Picture"
      style="width: 40px; height: 40px; border-radius: 50%; object-fit: cover;"
    />`;
  } else {
    const nameInitials = createNameInitials(
      { firstName: algoliaItem.firstName, lastName: algoliaItem.lastName },
      i18n.language,
    );

    profileElement = html`<div
      style="width: 40px; height: 40px; border-radius: 50%; display: flex; align-items: center; justify-content: center; background-color: #ccc; font-size: 18px; color: #fff; font-weight: bold;"
    >
      ${nameInitials}
    </div>`;
  }

  return html`<style>
      .aa-ItemLink {
        display: flex;
        justify-content: flex-start;
      }
    </style>

    <a
      class="aa-ItemLink"
      href=${getInstantSearchUrl(uiState)}
      onClick=${(event: any) => {
        if (!isModifierEvent(event)) {
          event.preventDefault();
        }
      }}
    >
      ${profileElement} ${doctorDescription(algoliaItem as unknown as AlgoliaItem)}
    </a>`;
}

const querySuggestionsPlugin = createQuerySuggestionsPlugin({
  searchClient,
  indexName: indexName,
  transformSource({ source }: any) {
    return {
      ...source,
      sourceId: 'querySuggestionsPlugin',
      getItemUrl({ item }: any) {
        return getItemUrl({
          query: item.query,
        });
      },
      onSelect({ setIsOpen, setQuery, event, item }: any) {
        onSelect({
          setQuery,
          setIsOpen,
          event,
          query: doctorDescription(item as AlgoliaItem),
          algoliaItem: item,
        });
      },
      getItems(params) {
        if (!params.state.query) {
          return [];
        }

        //this is the original version from Algolia, returns only up to 5 items by default:
        //return source.getItems(params);

        return getAlgoliaResults({
          searchClient,
          queries: [
            {
              indexName: indexName,
              query: params.state.query,
              params: {
                hitsPerPage: 10, // This number controls how many results we show in the dropdown
              },
            },
          ],
        });
      },
      templates: {
        ...source.templates,
        item(params) {
          const { children } = (source.templates.item(params) as any).props;
          const { item, html }: any = params;

          return getItemWrapper({
            query: item.query,
            algoliaItem: item,
            html,
            children,
          });
        },
      },
    };
  },
});

const searchPageState = getInstantSearchUiState();

export function startAutocomplete(searchInstance: InstantSearch) {
  let skipInstantSearchStateUpdate = false;

  const placeholder = i18n.t('algoliaSearchPlaceholder');
  const { setQuery } = autocomplete({
    container: '#autocomplete',
    placeholder: placeholder,
    insights: true,
    openOnFocus: true,
    plugins: [querySuggestionsPlugin],
    detachedMediaQuery: 'none',
    initialState: {
      query: searchPageState.query || '',
    },
    navigator: {
      navigate() {
        // We don't navigate to a new page because we leverage the InstantSearch
        // UI state API.
      },
    },
    onSubmit({ state }) {
      setInstantSearchUiState({ query: state.query });
    },
    onReset() {
      //reset/clean scheduler view
      document.dispatchEvent(new CustomEvent('algoliaSearchStateUpdated', { detail: { objectID: 0 } }));
      setInstantSearchUiState({
        query: '',
      });
    },
    onStateChange({ prevState, state }) {
      if (!skipInstantSearchStateUpdate && prevState.query !== state.query) {
        debouncedSetInstantSearchUiState({ query: state.query });
      }
      skipInstantSearchStateUpdate = false;
    },
  });

  window.addEventListener('popstate', () => {
    skipInstantSearchStateUpdate = true;
    setQuery((searchInstance.helper && searchInstance.helper.state.query) || '');
  });
}
