import { enhancedApi } from './user.generated';

export const registerEnhancedUserEndpoints = () => {
  enhancedApi.enhanceEndpoints({
    endpoints: {
      getAllUserMultiLang: {
        providesTags: () => ['User'],
      },
      getAllUser: {
        providesTags: () => ['User'],
      },
      updateUser: {
        invalidatesTags: ['User'],
      },
      registerApplicationUser: {
        invalidatesTags: ['User'],
      },
      deleteUser: {
        invalidatesTags: ['User'],
      },
      addUserPicture: {
        invalidatesTags: ['User'],
      },
    },
  });
};
