import { AppointmentDTO } from '../../api/medapp/generated';
import { getTranslatedAppointmentType } from './utils';
import { CSSProperties } from 'react';

export interface DataCellProps {
  isTimeSlotEnabled: boolean;
}

export function DataCell(props: DataCellProps) {
  let cssClasses = '';

  if (!props.isTimeSlotEnabled) {
    cssClasses += 'disable-date';
  }

  return <div className={cssClasses} />;
}

export type Appointment = {
  allDay: boolean;
  description: string;
  endTime: string;
  startTime: string;
  displayStartDate: Date;
  displayEndDate: Date;
  title: string;
};

const parentDivStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
  whiteSpace: 'pre-wrap',
};

export function AppointmentComponent(appointment: AppointmentDTO) {
  const translatedAppointmentType = getTranslatedAppointmentType(appointment.appointmentType);
  //be careful here not to take the translated appointment type
  switch (appointment.appointmentType) {
    case AppointmentDTO.appointmentType.ABSENCE:
      return <div className="dx-scheduler-appointment-title" />;
    default:
      return (
        <div className="dx-scheduler-appointment-title" style={parentDivStyle}>
          <div style={{ display: 'flex'}}>
            <span>
              <strong>{appointment.patientName}</strong>
            </span>
          </div>
          <div style={{ display: 'flex'}}>
            <span>{`${appointment.phoneNumber} (${translatedAppointmentType})`}</span>
          </div>
        </div>
      );
  }
}
