import { AnyAction, combineReducers } from 'redux';
import user from './user';
import organization from './organization';
import address from './address';
import clinic from './clinic';
import { medAppApi } from '../../api/medappApi';

const combinedReducers = combineReducers({
  user,
  organization,
  address,
  clinic,
  [medAppApi.reducerPath]: medAppApi.reducer,
});

export const rootReducer = (state: ReturnType<typeof combinedReducers> | undefined, action: AnyAction) => {
  return combinedReducers(state, action);
};

export type RootReducerType = typeof rootReducer;
export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
