/*
  The Contents of this file were generated by Devextreme CLI.
  Please do not touch the content of this directory unless you know what you are doing.
*/

import { useState, useCallback, useEffect } from 'react';

type ScreenSizes = {
  isXSmall: boolean;
  isSmall: boolean;
  isMedium: boolean;
  isLarge: boolean;
};

export const useScreenSize = (): ScreenSizes => {
  const [screenSize, setScreenSize] = useState(getScreenSize());
  const onSizeChanged = useCallback(() => {
    setScreenSize(getScreenSize());
  }, []);

  useEffect(() => {
    subscribe(onSizeChanged);

    return () => {
      unsubscribe(onSizeChanged);
    };
  }, [onSizeChanged]);

  return screenSize;
};

type ScreenSizeClasses = 'screen-large' | 'screen-medium' | 'screen-small' | 'screen-x-small';

export const useScreenSizeClass = (): ScreenSizeClasses => {
  const screenSize = useScreenSize();

  if (screenSize.isLarge) {
    return 'screen-large';
  }

  if (screenSize.isMedium) {
    return 'screen-medium';
  }

  if (screenSize.isSmall) {
    return 'screen-small';
  }

  return 'screen-x-small';
};

type HandlerFunction = () => void;
let handlers: HandlerFunction[] = [];
const xSmallMedia = window.matchMedia('(max-width: 599.99px)');
const smallMedia = window.matchMedia('(min-width: 600px) and (max-width: 959.99px)');
const mediumMedia = window.matchMedia('(min-width: 960px) and (max-width: 1279.99px)');
const largeMedia = window.matchMedia('(min-width: 1280px)');

[xSmallMedia, smallMedia, mediumMedia, largeMedia].forEach((media) => {
  media.addListener((e) => {
    e.matches && handlers.forEach((handler) => handler());
  });
});

const subscribe = (handler: HandlerFunction) => handlers.push(handler);

const unsubscribe = (handler: HandlerFunction) => {
  handlers = handlers.filter((item) => item !== handler);
};

function getScreenSize() {
  return {
    isXSmall: xSmallMedia.matches,
    isSmall: smallMedia.matches,
    isMedium: mediumMedia.matches,
    isLarge: largeMedia.matches,
  };
}
