import { enhancedApi } from './city.generated';

export const registerEnhancedCityEndpoints = () => {
  enhancedApi.enhanceEndpoints({
    endpoints: {
      getAllCityMultiLang: {
        providesTags: () => ['City'],
      },
      createCity: {
        invalidatesTags: ['City'],
      },
      updateCity: {
        invalidatesTags: ['City'],
      },
    },
  });
};
